import "../_styles/style.css";
import ENG from "./lang/eng";
import SR from "./lang/sr";
import React from "react";
import Welcome from "./sections/a-welcome";
import MidIcons from "./sections/b-mid-icons";
import OfferPackages from "./sections/c-packages";
import CataSlide from "./sections/d-cataSlide";
import Platforms from "./sections/f-platforms";
import HomeContact from "./sections/e-contact";
import Footer from "../Footer/footer";

const Home = ({ lang }) => {
  
  const [Lang] = lang === "sr" ? SR : ENG;

  return (
    <React.Fragment>
      <div id="homeContent">
        <Welcome Lang={Lang} />
        <MidIcons Lang={Lang} />
        <OfferPackages Lang={Lang} />
        <CataSlide Lang={Lang} />
        <HomeContact Lang={Lang} />
        <Platforms Lang={Lang} />
      </div>
      <Footer lang={lang} />
    </React.Fragment>
  );
};

export default Home;
