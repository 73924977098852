const ENG = [
  {
    infotitle: "Info site",
    infosubtitle:
      "We offer high-quality informational websites tailored to your needs. Whether it's a personal presentation, business profile, or organization, our team of web development experts ensures that your site is visually appealing, user-friendly, and mobile-friendly.",
    infoincludes: "Package includes:",
    info1main: "Basic Information:",
    info1submain1: "Detailed description of products or services you offer",
    info1submain2: "Contact information, including address, phone, and email",
    info1submain3: "Map with exact location or directions to reach you",
    info1submain4: "Working hours or service availability",
    info2main: "Gallery of Images and Videos:",
    info2submain1: "Visual display of products, services, or completed projects",
    info2submain2: "Video presentations of products or service demonstrations",
    info2submain3: "Team photos or workplace environment, providing a personal touch",
    info2submain4: "Descriptions or stories related to each photo or video",
    info2submain5: "Option to share visual content on social media",
    info3main: "Client Experiences:",
    info3submain1: "Client references or displays of previous projects",
    info3submain2: "Statements from satisfied customers with their experiences",
    info3submain3: "Certificates, awards, or acknowledgments contributing to authority",

    cmstitle: "Content Management System Website",
    cmsincludes: "Package includes:",
    cmssubtitle:
      "We offer high-quality Content Management System (CMS) websites tailored to your needs. Whether it's managing multiple web pages, creating content, or analyzing performance, our team of web development experts ensures that your CMS is visually appealing, user-friendly, and mobile-friendly.",

    cms1main: "Key Features:",

    cms1submain1: "User panel for easy content addition, editing, and deletion of content",
    cms1submain2: "Multi-user support with different access levels",
    cms1submain3: "File manager for browsing and managing data at the storage level",
    cms1submain4: "Advanced content search and filtering for quick access to necessary information",

    cms3main: "User Training:",
    cms3submain1: "Personalized training for your team to ensure you maximize all platform capabilities.",
    cms3submain2: "Trainings are tailored to your specific needs and can be conducted online or on-site, as per your choice.",
    cms3submain3: "During the training, you will learn all CMS features, enabling you to independently manage and maintain your websites.",

    cms2main: "Functionality demonstration (click to preview):",
    cms2submain1: "Adding, editing, and deleting content",
    cms2submain2: "Adding, editing, and deleting team members",
    cms2submain3: "Editing permissions for team members",
    cms2submain4: "Browsing and managing data at the storage level using the file manager",
    cms2submain5: "FAQ and customer support contact",

    backbutton: "Back",
    contactbutton: "Contact",
  },
];

export default ENG;
