import IzradaSVG from "./_images/icons/izrada";
import SeoSVG from "./_images/icons/seo";
import SocialSVG from "./_images/icons/social";

const MidIcons = ({ Lang }) => {
  return (
    <section className="py-8 py-md-11 part1 mid-icons">
      <div className="container">
        <div className="row">
          <div
            className="col-12 col-md-4 aos-init aos-animate part1el"
            data-aos="fade-up"
          >
            <div className="icon text-primary mb-3">
              <IzradaSVG />
            </div>

            <h3 className="text-white">{Lang.services1h3}</h3>

            <p className="mb-6 mb-md-0 white-gray">{Lang.services1p}</p>
          </div>
          <div
            className="col-12 col-md-4 aos-init aos-animate part1el"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <div className="icon text-primary mb-3">
              <SeoSVG />
            </div>

            <h3 className="text-white">{Lang.services2h3}</h3>

            <p className="mb-6 mb-md-0 white-gray">{Lang.services2p}</p>
          </div>
          <div
            className="col-12 col-md-4 aos-init aos-animate part1el"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="icon text-primary mb-3">
              <SocialSVG />
            </div>

            <h3 className="text-white">{Lang.services3h3}</h3>

            <p className="mb-0 white-gray">{Lang.services3p}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MidIcons;
