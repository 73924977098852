import React, { useState, useEffect } from "react";
import ENG from "./lang/eng";
import SR from "./lang/sr";
import Card from "../../common/card/card";
import CardData from "../../common/card/data";
import Pagination from "../../common/pagination";
import { paginate } from "../../utils/paginate";
import CardButton from "../../common/card/button";
import Footer from "../Footer/footer";
import { CataDB } from "../../db/CataDB";

const Catalouge = ({ lang }) => {
  const [Lang] = lang === "sr" ? SR : ENG;

  const [cards, setCards] = useState([]);
  const [pageSize] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getAnimations();
  }, []);

  useEffect(() => {
    if (cards.length === 0) {
      getAnimations();
    }
  }, [cards]);

  const getAnimations = () => {
    setTimeout(() => {
      setCards(CataDB);
      setLoaded(true);
    }, 300);
  };

  const getLength = () => {
    const cardsGet = [...cards];
    const allCards = paginate(cardsGet, currentPage, pageSize);
    const allCardsLength = cardsGet.length;
    return { allCards, allCardsLength };
  };

  const topPage = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    topPage();
  };

  const handleNextPage = () => {
    const { allCardsLength } = getLength();
    if (currentPage < allCardsLength / pageSize) {
      setCurrentPage(currentPage + 1);
      topPage();
    }
  };

  const handlePrevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      topPage();
    }
  };

  return (
    <React.Fragment>
   
        <div>
          <div
            className="album py-5 bg-light"
            id="cardContent"
          >
            <div className="container katalogContainer">
              <div
                id="cardlist"
                className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3"
              >
                {loaded ?
                  getLength().allCards.map(card => (
                    <li key={card._id}>
                      <Card
                        data={
                          <CardData
                            img={card._img}
                            label={card._label}
                            text={lang === "sr" ? card._text.SR : card._text.ENG}
                          />
                        }
                        button={
                          <CardButton
                            id={card._id}
                            button1={card._url}
                            buttonlabel={Lang.goto}
                          />
                        }
                      />
                    </li>
                  )) : (
                    <div>
                      <div className="loadingBox">
                        <div className="loadingBoxPosition">
                          <div className="loadingBoxContent">
                            <div className="lds-dual-ring"></div>
                            <p className="txtColorUci">{Lang.loading}</p>
                          </div>
                        </div>
                      </div>
                      <div className="spaceToFooter"></div>
                    </div>
                  ) }
              </div>
              {loaded && (
                <Pagination
                  itemsCount={getLength().allCardsLength}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  nextPage={handleNextPage}
                  prevPage={handlePrevPage}
                />
              )}
            </div>
          </div>
          {loaded && <Footer lang={lang}/>}
        </div>
    
    </React.Fragment>
  );
};

export default Catalouge;
