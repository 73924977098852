import React from "react";
import Small from "../../../../../utils/small";
import { Link } from "react-router-dom";

export default function InfoSmall({ Lang, topPage, showSmallInfoOffer }) {
  return (
    <div className={`col-lg-3 mb-lg-auto mb-4 my-auto p-md-0 ms-auto infoSajt smallOffer  ${showSmallInfoOffer}`}>
      <div className="card card-offer border-radius-top-end-lg-0 border-radius-bottom-end-lg-0">
        <div className="card-header text-center pt-4 pb-3">
          <h6 className="text-dark opacity-8 mb-0">{Lang.offersinfotitle}</h6>
        </div>

        <div className="card-footer pt-0">
          <Link
            onClick={topPage}
            to="/info-site"
            className="btn button1 w-100 bg-gradient-dark mb-0 moreinfo"
          >
            {Lang.moreinfo}
          </Link>
        </div>
      </div>
    </div>
  );
}
