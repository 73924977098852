import React from 'react'

export default function Small({text}) {
  return (
    <div>  <small>
    <small>
        <small>
            <small>
                <small>
                    <small>
                        <small>
                            {text}
                        </small>
                    </small>
                </small>
            </small>
        </small>
    </small>
</small></div>
  )
}
