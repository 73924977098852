import React from "react";

const CardData = ({ img, label, text }) => {
  if ((img, label)) {
    return (
      <React.Fragment>
        <img
          src={img}
          className="bd-placeholder-img card-img-top"
          width="100%"
          height="225"
          aria-label={label}
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        />

        <span
          className="card-body-uniq appHeader"
          x="50%"
          y="50%"
          fill="#eceeef"
          dy=".3em"
        >
          {label}
        </span>
        <div className="card-body-uniq">
          <p className="card-text">{text}</p>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <svg
          className="bd-placeholder-img card-img-top"
          width="100%"
          height="225"
          aria-label="Under construction"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        >
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#55595c"></rect>
          <text x="50%" y="50%" fill="#eceeef" dy=".3em">
            No touchy
          </text>
        </svg>
        <span
          className="card-body-uniq appHeader"
          x="50%"
          y="50%"
          fill="#eceeef"
          dy=".3em"
        >
          {label}
        </span>
        <div className="card-body-uniq">
          <p className="card-text">Comming soon</p>
        </div>
      </React.Fragment>
    );
  }
};

export default CardData;
