const SR = [
  {
    //Nav
    navHome: `Početna`,
    navCata: `Katalog`,
    navShop: `Trgovina`,
    navContact: `Kontakt`,
    sr: `Srpski`,
    eng: `Engleski`,
  },
];

export default SR;
