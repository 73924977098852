import React from 'react';
import PrimaryButton from '../../../utils/button';
import ContactIcon from '../../a-Navbar/common/_images/kontakt';

export default function HomeContact({Lang}) {
    return (
        <section className='py-8 py-md-11'>
            <div className='text-center'>
                <p
                    id='ponuda'
                    className='lead medjutekst'
                >
                    {Lang.contacttitle}
                </p>

                <PrimaryButton
                    to='/kontakt'
                    name={Lang.contactbutton}
                    classpass={`prime-button contactsvg`}
                    img={<ContactIcon />}
                />
            </div>
        </section>
    );
}
