import React from "react";

const CatalogeIcon = () => {
  return (
    <svg
      className="bi d-block mx-auto mb-1 bi bi-cart2"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="200 0 90 500"
    >
      <g>
        <g>
          <path d="m480.6,11c-40.6,0-80.2,12.7-118.6,25-36.9,11.9-71.8,23.1-106,23.1-34.2,0-69.1-11.2-106-23.1-38.3-12.3-78-25-118.6-25-11.3,0-20.4,9.1-20.4,20.4v401c0,11.3 9.1,20.4 20.4,20.4 34.2,0 69.1,11.2 106,23.1 38.3,12.3 77.9,25 118.5,25 40.6,0 80.2-12.7 118.5-25 36.9-11.9 71.8-23.1 106-23.1 11.3,0 20.4-9.1 20.4-20.4v-401c0.2-11.3-8.9-20.4-20.2-20.4zm-428.8,402.1v-360c27.7,3.2 56,12.3 85.6,21.9 31.9,10.2 64.6,20.8 98.1,24v360c-27.7-3.2-56-12.3-85.6-21.8-31.8-10.4-64.6-20.9-98.1-24.1zm408.4,0c-33.5,3.3-66.3,13.8-98.1,24-29.6,9.5-57.9,18.6-85.6,21.8v-360c33.5-3.3 66.3-13.8 98.1-24 29.6-9.5 57.9-18.6 85.6-21.9v360.1z" />
          <path d="m183.6,144l-68.1-20.4c-10.8-3.2-22.2,2.9-25.4,13.7-3.2,10.8 2.9,22.2 13.7,25.4 0,0 72,21.3 73.9,21.3 8.8,0 16.9-5.7 19.5-14.6 3.3-10.7-2.8-22.1-13.6-25.4z" />
          <path d="m183.6,246.6l-68.1-20.4c-10.8-3.2-22.2,2.9-25.4,13.7-3.2,10.8 2.9,22.2 13.7,25.4 0,0 72,21.3 73.9,21.3 8.8,0 16.9-5.7 19.5-14.6 3.3-10.7-2.8-22.1-13.6-25.4z" />
          <path d="m183.6,349.3l-68.1-20.4c-10.8-3.2-22.2,2.9-25.4,13.7-3.2,10.8 2.9,22.2 13.7,25.4 0,0 72,21.3 73.9,21.3 8.8,0 16.9-5.7 19.5-14.6 3.3-10.8-2.8-22.2-13.6-25.4z" />
          <path d="m314.7,169.4c2.7,8.9 10.8,14.6 19.5,14.6 1.9,0 73.9-21.3 73.9-21.3 10.8-3.2 16.9-14.6 13.7-25.4-3.3-10.8-14.6-16.9-25.4-13.7l-68,20.4c-10.8,3.3-16.9,14.7-13.7,25.4z" />
          <path d="m396.5,226.2l-68.1,20.4c-10.8,3.2-16.9,14.6-13.7,25.4 2.7,8.9 10.8,14.6 19.5,14.6 1.9,0 73.9-21.3 73.9-21.3 10.8-3.2 16.9-14.6 13.7-25.4-3.2-10.8-14.5-16.8-25.3-13.7z" />
          <path d="m396.5,328.9l-68.1,20.4c-10.8,3.2-16.9,14.6-13.7,25.4 2.7,8.9 10.8,14.6 19.5,14.6 1.9,0 73.9-21.3 73.9-21.3 10.8-3.2 16.9-14.6 13.7-25.4-3.2-10.8-14.5-16.9-25.3-13.7z" />
        </g>
      </g>
    </svg>
  );
};

export default CatalogeIcon;
