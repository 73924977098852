import React from "react";
import QButton from "./button";

const PocetakP = props => {
  const pocetak = props.show;
  const handleShow = props.handleShow;
  const Lang = props.Lang;

  return (
    <React.Fragment>
      <p
        className="fs-5 mb-4 py-5"
        id={`pocetakp${pocetak}`}
      >
        <br />
        {Lang.surveysubtitle1}

        <br />
        {Lang.surveysubtitle2}
      </p>
      <div className="d-grid gap-2 d-sm-flex justify-content-sm-center bd-example">
        <QButton
          name={Lang.buttonstart}
          handleShow={handleShow}
          pocetak={pocetak}
        />
      </div>
    </React.Fragment>
  );
};

export default PocetakP;
