import React from "react";
import Small from "../../../../../utils/small";
import { Link } from "react-router-dom";

export default function StoreSmall({ Lang, showSmallStoreOffer }) {
  return (
    <div className={`col-lg-3 p-md-0 mb-lg-auto mb-4 z-index-2 kontentSajt smallOffer ${showSmallStoreOffer}`}>
      <div className="card">
        <div className="card-header text-center pt-4 pb-3">
          <h6 className="text-dark opacity-8 text mb-0 mt-2">{Lang.offersshoptitle}</h6>
        </div>
        <div className="card-footer pt-0">
          <p className="btn button3 w-100 bg-gradient-dark mb-0 moreinfo">{Lang.commingsoon}</p>
        </div>
      </div>
    </div>
  );
}
