import React from "react";
export let odg03 = 0;

const Q3 = (props) => {
  const show = props.show;
  const handleShow = props.handleShow;
  const Lang = props.Lang;

  const handleAnswer = (x) => {
    odg03 = x;

    return handleShow();
  };

  return (
    <div>
      <div
        className="bg-dark text-secondary px-4 py-5 text-center"
        id={`pitanje3${show}`}
      >
        <div className="py-5">
          <div className="mx-auto pitanje1">
            <p className="fs-5 mb-4">{Lang.q3}</p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <button
                type="button"
                className="btn button1 btn-outline-info btn-lg px-4 me-sm-3 fw-bold odgovori "
                onClick={() => {
                  handleAnswer(100);
                }}
              >
                {Lang.q3a1}
              </button>

              <button
                type="button"
                className="btn button1 btn-outline-info btn-lg px-4 me-sm-3 fw-bold odgovori"
                onClick={() => {
                  handleAnswer(0);
                }}
              >
                {Lang.q3a2}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Q3;
