import React from "react";
import { useSpring, animated } from "react-spring";

const Card = ({ data, button, animation}) => {

  const anix = useSpring({
    to: { opacity: 1, marginLeft: 0 },
    from: { opacity: 0, marginLeft: -1500 },
    //delay: 100,
    //config: { duration: 1000 },
  });

  
  return (
    <animated.div className="col" style={anix}>
      <div className="card shadow-sm">
        {data}
        {button}
        {animation}
      </div>
    </animated.div>
  );
};

export default Card;
