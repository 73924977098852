const ENG = [
  {
    servicestitle: `Services`,
    servicessubtitle: `We provide services for creating and promoting websites as well as their maintenance.`,

    service1title: `Website Development`,
    service1subtitle: `We create pages according to your needs and desires.`,
    service1des1: `Informational and promotional websites`,
    service1des2: `Pages with content management`,
    service1des3: `E-commerce websites`,

    service2title: `SEO`,
    service2subtitle: `Website optimization for search engines`,
    service2des1: `Increasing the quantity and quality of traffic to your website`,
    service2des2: `Improving search engine result page rankings`,
    service2des3: `Off-site optimization`,

    service3title: `Social Media`,
    service3subtitle: `Connect and easily share content between your site and social networks.`,
    service3des1: `Connecting with existing social networks`,
    service3des2: `Opening new social networks`,
    service3des3: `Content maintenance on social networks`,

    backbutton: `Back`,
  },
];

export default ENG;
