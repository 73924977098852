const CardButton = ({ id, button1, buttonlabel }) => {
  return (
    <div className="card-body-uniq">
      <div className="d-flex justify-content-between align-items-center">
        <div className="btn-group">
          {button1 ? (
            <a
              type="button"
              className="btn btn-sm btn-outline-secondary-uniq"
              target="_blank"
              rel="noopener noreferrer"
              id={id}
              href={button1}
            >
              {buttonlabel}
            </a>
          ) : (
            <p className="btn button4 btn-outline-secondary-uniq"> {buttonlabel}</p>
          )}
          {/* {<button
            type="button"
            className="btn btn-sm btn-outline-secondary-uniq"
            onClick={button2}
          >
            Buy
          </button>} */}
        </div>
      </div>
    </div>
  );
};

export default CardButton;
