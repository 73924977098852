const SR = [
  {
    lang: `SR`,

    surveytitle: `Upitnik izrade web sajta`,
    surveysubtitle1: `Izradili smo ovaj kratki upitnik kako bismo vam pomogli da odaberete najbolju i najisplativiju opciju za vas, kao i da prikažemo približnu cijenu usluge prema vašim zahtjevima.`,
    surveysubtitle2: `Imajte na umu da je ovo okvirna cijena i da će završna cifra biti dogovorena tek nakon detaljnijeg razgovora koji možete zakazati putem naše kontakt stranice.`,

    q1: `Struktura web stranice:`,
    q1a1: `Jednostranična web stranica`,
    q1a2: `Višestranična web stranica`,

    q2: `Logotip:`,
    q2a1: `Potreban mi je logotip`,
    q2a2: `Imam već logotip`,

    q3: `Izgled stranice:`,
    q3a1: `Izgled web stranice prepuštam vama`,
    q3a2: `Imam zamišljen izgled svoje web stranice`,

    q4: `Na koliko jezika želite vaš sajt?`,
    q4a1: `Jedan jezik`,
    q4a2: `Više jezika`,

    q5: `Da li imate domen (internet adresu)?`,
    q5a1: `Potreban mi je domen`,
    q5a2: `Imam već domen`,

    q6: `Društvene mreže:`,
    q6a1: `Povezivanje sa postojećim`,
    q6a2: `Kreiranje novih`,
    q6a3: `Nije mi potrebno`,

    q7: `Da li planirate uređivati sadržaj web stranice?`,
    q7a1: `Planiram uređivati sadržaj`,
    q7a2: `Ne planiram uređivati sadržaj`,

    q8: `Da li planirate vršiti prodaju preko vaše web stranice?`,
    q8a1: `Planiram vršiti prodaju`,
    q8a2: `Ne planiram vršiti prodaju`,

    fintitle1: `Okvirna cijena za izradu sajta prema vašim odgovorima iznosi:`,
    finvalute: `KM`,
    fintitle11: `I najprikladnija opcija koju bismo preporučili, uzimajući u obzir vaše odgovore, bila bi:`,
    fintitle2: `Imajte na umu da je ovo okvirna cijena i da će završna cifra biti dogovorena tek nakon detaljnijeg razgovora koji možete zakazati putem naše kontakt stranice.`,
    fintitle3: `Za dodatna pitanja, detalje i zakazivanje sastanka, pronađite naše kontakt informacije na:`,

    buttonstart: `Započni`,

    offersinfotitle: `Informativni sajt`,
    offerscmstitle: `Sajt promjenjivog sadržaja`,
    offersshoptitle: `Internet trgovina`,
    moreinfo: `Više informacija`,
    commingsoon: `Dolazi uskoro`,

    contactbutton: `Kontakt`
  },
];

export default SR;
