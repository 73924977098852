const ENG = [
  {
    lang: `EN`,

    surveytitle: `Website Development Survey`,
    surveysubtitle1: `We have created this brief survey to help you choose the best and most cost-effective option for you, as well as to provide an approximate price for the service according to your requirements.`,
    surveysubtitle2: `Please note that this is an approximate price, and the final amount will be agreed upon only after a more detailed discussion, which you can schedule via our contact page.`,

    q1: `Website Structure:`,
    q1a1: `Single-page website`,
    q1a2: `Multi-page website`,

    q2: `Logo:`,
    q2a1: `I need a logo`,
    q2a2: `I already have a logo`,

    q3: `Website Design:`,
    q3a1: `I leave the website design to you`,
    q3a2: `I have a design in mind`,

    q4: `In how many languages do you want your website?`,
    q4a1: `One language`,
    q4a2: `Multiple languages`,

    q5: `Do you have a domain (internet address)?`,
    q5a1: `I need a domain`,
    q5a2: `I already have a domain`,

    q6: `Social Media:`,
    q6a1: `Linking to existing ones`,
    q6a2: `Creating new ones`,
    q6a3: `I don't need this`,

    q7: `Do you plan to edit the website content?`,
    q7a1: `I plan to edit content`,
    q7a2: `I do not plan to edit content`,

    q8: `Do you plan to sell articles through your website?`,
    q8a1: `I plan to sell articles`,
    q8a2: `I do not plan to sell articles`,

    fintitle1: `The estimated price for website development according to your answers is:`,
    finvalute: `$`,
    fintitle11: `And the most suitable option we would recommend, taking into account your responses, would be:`,
    fintitle2: `Please note that this is an approximate price, and the final amount will be agreed upon only after a more detailed discussion, which you can schedule via our contact page.`,
    fintitle3: `For additional questions, details, and appointment scheduling, find our contact information at:`,

    buttonstart: `Start`,

    offersinfotitle: `Informative Website`,
    offersinfoprice: `100`,
    offerscmstitle: `Dynamic Content Website`,
    offerscmsprice: `200`,
    offersshoptitle: `Online Shop`,
    offersshopprice: `400`,

    from: `starting from `,
    offerssign: `$`,
    moreinfo: `More Information`,
    commingsoon: `Coming Soon`,

    contactbutton: `Contact`
  },
];

export default ENG;
