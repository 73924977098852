import React from "react";
export let odg08 = 0;

const Q8 = (props) => {
  const show = props.show;
  const handleShow = props.handleShow;
  const Lang = props.Lang;
  const setShowSmallStoreOffer = props.setShowSmallStoreOffer

  const handleAnswer = (x) => {
    odg08 = x;

    return handleShow();
  };

  return (
    <div>
      <div className="bg-dark text-secondary px-4 py-5 text-center" id={`pitanje8${show}`}>
        <div className="py-5">
          <div className="mx-auto pitanje1">
            <p className="fs-5 mb-4">{Lang.q8}</p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <button
                type="button"
                className="btn button1 btn-outline-info btn-lg px-4 me-sm-3 fw-bold odgovori"
                onClick={() => {
                  handleAnswer(600);
                  setShowSmallStoreOffer("show")
                }}
              >
                {Lang.q8a1}
              </button>

              <button
                type="button"
                className="btn button1 btn-outline-info btn-lg px-4 me-sm-3 fw-bold odgovori"
                onClick={() => {
                  handleAnswer(0);
                }}
              >
                {Lang.q8a2}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Q8;
