import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
    EffectCoverflow,
    Pagination,
    Navigation,
    Autoplay,
} from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import slide_image_1 from '../_images/odd.png';
import slide_image_2 from '../_images/dash.png';
import slide_image_3 from '../_images/exq.png';
import slide_image_4 from '../_images/elnor.png';
import slide_image_5 from '../_images/dash2.png';
import slide_image_6 from '../_images/exq.png';

import PrimaryButton from '../../../utils/button';
import CatalogeIcon from '../../a-Navbar/common/_images/katalog';

export default function CataSlide({Lang}) {
    const [opacity, setOpacity] = useState('');
    const [opacityOn, setOpacityOn] = useState('');

    const onHover = () => {
        setOpacity('opacity-fade');
        setOpacityOn('homeCataButtonOn');
    };

    const onHoverOut = () => {
        setOpacity('');
        setOpacityOn('');
    };

    return (
        <section className='py-8 py-md-11'>
            <div className='text-center'>
                <p
                    id='ponuda'
                    className='lead medjutekst'
                >
                    {Lang.cataloguetitle}
                </p>
            </div>
            <div
                className={`container-cataSlide `}
                onMouseEnter={() => onHover()}
                onMouseLeave={() => onHoverOut()}
            >
                <Swiper
                    effect={'coverflow'}
                    grabCursor={false}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 2.5,
                    }}
                    pagination={{ el: '.swiper-pagination', clickable: false }}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                        clickable: false,
                    }}
                    modules={[
                        EffectCoverflow,
                        Pagination,
                        Navigation,
                        Autoplay,
                    ]}
                    className={`swiper_container ${opacity}`}
                >
                    <SwiperSlide>
                        <img
                            src={slide_image_1}
                            alt='slide_image'
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={slide_image_2}
                            alt='slide_image'
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={slide_image_3}
                            alt='slide_image'
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={slide_image_4}
                            alt='slide_image'
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={slide_image_5}
                            alt='slide_image'
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={slide_image_6}
                            alt='slide_image'
                        />
                    </SwiperSlide>

                    <div className='slider-controler'>
                        {/* <div className='swiper-button-prev slider-arrow'>
                            <ion-icon name='arrow-back-outline'></ion-icon>
                        </div>
                        <div className='swiper-button-next slider-arrow'>
                            <ion-icon name='arrow-forward-outline'></ion-icon>
                        </div> 
                        <div className='swiper-pagination'></div>  */}
                    </div>
                </Swiper>
                <div className='slider-controler'>
                    <PrimaryButton
                        style={{ marginTop: -245, zIndex: 5 }}
                        to='/katalog'
                        name={Lang.cataloguebutton}
                        classpass={`prime-button homeCataButton ${opacityOn}`}
                        img={CatalogeIcon()}
                    />
                </div>
            </div>
        </section>
    );
}
