import ENG from "./lang/eng";
import SR from "./lang/sr";
import SvgCheck from "../../_images/check";
import Arrow from "../../_images/arrow";
import PrimaryButton from "../../../../utils/button";
import BackArrow from "../../../_styles/icons/back-arrow";
import ContactIcon from "../../../a-Navbar/common/_images/kontakt";
import Footer from "../../../Footer/footer";

//import presentation from './_images/anim.gif'

const InfoSite = ({ lang }) => {
  const [Lang] = lang === "sr" ? SR : ENG;
  return (
    <section className="pt-4 pt-md-11 pogodnosti-naslov">
      <div className="container">
        <div className="col-12 order-md-1">
          <h1 className="display-3 text-center text-md-start">{Lang.infotitle}</h1>
          <p className="lead text-center text-md-start text-muted mb-6 mb-lg-8">{Lang.infosubtitle}</p>
        </div>
        <div className="row align-items-center ">
          <div className="col-12 col-md-7 col-lg-6 info1">
            <p className="lead text-center text-md-start sadrzaj">{Lang.infoincludes}</p>

            <section className="pogodnosti">
              {/* INFO 1*/}
              <div className="justify-content-start d-flex px-2 py-1 py-1">
                <div>
                  <SvgCheck />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info1main}</span>
                </div>
              </div>

              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info1submain1}</span>
                </div>
              </div>
              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info1submain2}</span>
                </div>
              </div>
              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info1submain3}</span>
                </div>
              </div>
              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info1submain4}</span>
                </div>
              </div>

              <br />
              {/* INFO 2*/}
              <div className="justify-content-start d-flex px-2 py-1 py-1">
                <div>
                  <SvgCheck />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info2main}</span>
                </div>
              </div>

              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info2submain1}</span>
                </div>
              </div>
              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info2submain2}</span>
                </div>
              </div>
              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info2submain3}</span>
                </div>
              </div>
              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info2submain4}</span>
                </div>
              </div>
              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info2submain5}</span>
                </div>
              </div>

              <br />
              {/* INFO 3*/}
              <div className="justify-content-start d-flex px-2 py-1 py-1">
                <div>
                  <SvgCheck />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info3main}</span>
                </div>
              </div>

              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info3submain1}</span>
                </div>
              </div>
              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info3submain2}</span>
                </div>
              </div>
              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.info3submain3}</span>
                </div>
              </div>
            </section>
          </div>
          <div className="col-12 col-md-7 col-lg-6 order-md-1 info2">
            <img
              className="infoVideo"
              src="/videos/info.gif"
              alt="presentation website"
            />
            <div className="row buttonPackages">
              <div className="col packagesButL">
                <PrimaryButton
                  to="/"
                  name={Lang.backbutton}
                  classpass={`prime-button backPackage`}
                  img={
                    <BackArrow
                      h={20}
                      w={20}
                    />
                  }
                />
              </div>
              <div className="col packagesButR">
                <PrimaryButton
                  to="/kontakt"
                  name={Lang.contactbutton}
                  classpass={`prime-button contactPackage`}
                  img={<ContactIcon />}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row buttonPackagesPhone">
          <div className="col packagesButL">
            <PrimaryButton
              to="/"
              name={Lang.backbutton}
              classpass={`prime-button backPackage`}
              img={
                <BackArrow
                  h={20}
                  w={20}
                />
              }
            />
          </div>
          <div className="col packagesButR">
            <PrimaryButton
              to="/kontakt"
              name={Lang.contactbutton}
              classpass={`prime-button contactPackage`}
              img={<ContactIcon />}
            />
          </div>
        </div>
      </div>
      <Footer lang={lang} />
    </section>
  );
};

export default InfoSite;
