const ENG = [
  {
    //Nav
    navHome: "Home",
    navCata: "Catalogue",
    navShop: "Shop",
    navContact: "Contact",
    sr: `Serbian`,
    eng: `English`,
    
  },
];

export default ENG;
