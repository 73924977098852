const ENG = [
  {
    contacttitle: `Contact`,
    contactsubtitle: `Contact us via:`,
    contactnumtit: `Phone number:`,
    contactnum: `+387 66 618 640`,
    contactmailtit: `Email:`,
    contactmail: `odd.genetics@hotmail.com`,
    contactsocialtit: `Social networks:`,
    contactsocial: `In progress`,
    contactformtitle: `Or fill out and submit the form and we will then contact you:`,
    contactformname: `Full name`,
    contactformphone: `Phone number`,
    contactformmail: `E-mail`,
    contactformtxt: `Message`,
    contactformsuccessmsg: `Message sent successfully!`,
    contactformerrmsg: `Please fill in correctly the required fields!`,
    contactformbutton: `Send`,

    //typewrite animation
    animationtitle: `Your website`,
    animationh1: `Title`,
    animationp: `Text`,
    animationsrcandalt: `image`,
  },
];

export default ENG;
