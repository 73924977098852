import React from "react";
import Small from "../../../../../utils/small";
import { Link } from "react-router-dom";

export default function CMSSmall({ Lang, topPage, showSmallCMSOffer }) {
  return (
    <div className={`col-lg-3 p-md-0 mb-lg-auto mb-4 z-index-2 kontentSajt smallOffer ${showSmallCMSOffer}`}>
      <div className="card">
        <div className="card-header text-center pt-4 pb-3">
          <h6 className="text-dark opacity-8 text mb-0 mt-2">{Lang.offerscmstitle}</h6>
        </div>
        <div className="card-footer pt-0">
          <Link
            onClick={topPage}
            to="/content-site"
            className="btn button1 w-100 bg-gradient-dark mb-0 moreinfo"
          >
            {Lang.moreinfo}
          </Link>
        </div>
      </div>
    </div>
  );
}
