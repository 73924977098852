export const QButton = (props) => {
 
  const handleShow = props.handleShow;
  const pocetak = props.pocetak;

  return (
    <button
      type="button"
      className="btn button1 btn-outline-info btn-lg px-4 me-sm-3 fw-bold"
      id={`start${pocetak}`}
      onClick={handleShow}
   
    >
      {props.name}
    </button>
  );
};

export default QButton;
