const SR = [
  {
    infotitle: `Info sajt`,
    infosubtitle: `Nudimo visokokvalitetne informativne sajtove prilagođene vašim potrebama. Bez obzira radi li se o osobnoj prezentaciji, poslovnom profilu
            ili organizaciji, naš tim stručnjaka za web development osigurava da vaš sajt bude vizualno privlačan, intuitivan za korisnike i
            prilagođen mobilnim uređajima.`,

    infoincludes: `Paket sadrži:`,

    info1main: `Osnovne informacije:`,
    info1submain1: `Detaljan opis proizvoda ili usluga koje nudite`,
    info1submain2: `Kontakt informacije, uključujući adresu, telefon i email`,
    info1submain3: `Mapa sa tačnom lokacijom ili uputstvima kako doći do vas`,
    info1submain4: `Radno vrijeme ili dostupnost usluga`,

    info2main: `Galerija slika i video snimaka:`,
    info2submain1: `Vizuelni prikaz proizvoda, usluga ili ostvarenih projekata`,
    info2submain2: `Video prezentacije proizvoda ili demonstracije usluga`,
    info2submain3: `Fotografije tima ili radnog okruženja, pružajući lični dodir`,
    info2submain4: `Opisi ili priče vezane uz svaku fotografiju ili video snimak`,
    info2submain5: `Opcija za dijeljenje vizualnog sadržaja na društvenim mrežama`,

    info3main: `Iskustva klijenata:`,
    info3submain1: `Reference klijenata ili prikazi prethodnih projekata`,
    info3submain2: `Izjave zadovoljnih korisnika sa njihovim iskustvima`,
    info3submain3: `Sertifikati, nagrade ili priznanja koja doprinose autoritetu`,

    //CMS
    cmstitle: "Sajt promjenjivog sadržaja",
    cmsincludes: "Paket sadrži:",
    cmssubtitle:
      "Nudimo visokokvalitetne Content Management System (CMS) sajtove prilagođene vašim potrebama. Bez obzira radi li se o upravljanju više web stranica, kreiranju sadržaja ili analizi performansi, naš tim stručnjaka za web development osigurava da vaš CMS bude vizualno privlačan, intuitivan za korisnike i prilagođen mobilnim uređajima.",

    cms1main: "Osnovne funkcionalnosti:",

    cms1submain1: "Korisnički panel za jednostavno dodavanje, uređivanje i brisanje sadržaja",
    cms1submain2: "Multikorisnička podrška sa različitim nivoima pristupa",
    cms1submain3: "File manager za pregled i upravljanje podacima na nivou skladišta",
    cms1submain4: "Napredna pretraga i filtriranje sadržaja za brzi pristup potrebnim informacijama",

    cms3main: "Korisničke obuke:",
    cms3submain1: "Personalizirane obuke za vaš tim kako bismo osigurali da maksimalno iskoristite sve mogućnosti platforme.",
    cms3submain2: "Obuke su prilagođene vašim specifičnim potrebama i mogu se održavati online ili na licu mjesta, prema vašem izboru.",
    cms3submain3: "Tokom obuke ćete naučiti sve funkcije CMS-a, što će vam omogućiti da samostalno upravljate i održavate svoje web stranice.",

    cms2main: "Primjeri funkcionalnosti (klikni da pogledaš):",
    cms2submain1: "Dodavanje, uređivanje i brisanje sadržaja",
    cms2submain2: "Dodavanje, uređivanje i brisanje članova tima",
    cms2submain3: "Uređivanje dozvola za članove tima",
    cms2submain4: "Pregled i upravljanje podacima na nivou skladišta pomoću file managera",
    cms2submain5: "FAQ i kontakt korisničke podrške",

    backbutton: "Nazad",
    contactbutton: "Kontakt",
  },
];

export default SR;
