export const CataDB = [
  {
    _id: 1,
    _img: "elnor.png",
    _label: "Elnor",
    _text: { SR: "Jednostranični info web sajt", ENG: "One-page info website" },
    _url: "https://elnor.ba/",
  },
  {
    _id: 2,
    _img: "xquisite.png",
    _label: "Xquisite",
    _text: { SR: "Višestranični info web sajt", ENG: "Multi-page info website" },
    _url: "https://demo1.oddgenetics.com/",
  },
  {
    _id: 3,
    _img: "videozone.jpg",
    _label: "Videozone",
    _text: { SR: "Web stranica sa bazom podataka, pretraživačem i filterima", ENG: "Website with database, search engine and filters" },
    _url: "https://demo2.oddgenetics.com/",
  },
  {
    _id: 4,
    _img: "dashboard.png",
    _label: "Dashboard",
    _text: { SR: "Kontrolna tabla za sajt promjenjivog sadržaja", ENG: "Dashboard for dynamic content website" },
    _url: "https://dashboard-mocha-six.vercel.app/",
  },

  { _id: 5, _img: "booking.png", _label: "BookinGo", _text: { SR: "U izradi...", ENG: "In progress..." }, _url: "https://booking-hazel-xi.vercel.app/" },
/*
  { _id: 6, _img: "", _label: "", _text: "", _url: "" },

  { _id: 7, _img: "", _label: "", _text: "", _url: "" },

  { _id: 8, _img: "", _label: "", _text: "", _url: "" },*/
];
