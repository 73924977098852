import { Link } from 'react-router-dom';
import React from 'react';
import TopPage from './topPage';

export default function PrimaryButton({ style, to, name, classpass, img }) {
    return (
        <Link
            style={style}
            onClick={TopPage}
            to={to}
        >
            <button className={classpass}>
                {img ? img : null} <span>{name}</span>
            </button>
        </Link>
    );
}
