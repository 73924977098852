import "../../../_styles/style.css";
import ENG from "./lang/eng";
import SR from "./lang/sr";
import React from "react";
import webd from "./_images/webd.png";
import social from "./_images/social.png";
import seo from "./_images/seo.png";
import PrimaryButton from "../../../../utils/button";
import BackArrow from "../../../_styles/icons/back-arrow";
import Footer from "../../../Footer/footer";

const Services = ({ lang }) => {
  const [Lang] = lang === "sr" ? SR : ENG;

  return (
    <section
      className="section section-lg pt-6"
      id="offersContent"
    >
      <div className="container">
        <div className="row justify-content-center mb-5 mb-lg-6">
          <div className="col-12 col-md-8 text-center controw">
            <h2 className="h1 font-weight-bolder mb-4 text-white">{Lang.servicestitle}</h2>
            <p className="lead">{Lang.servicessubtitle}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0 text-center">
            <div className="card border-light p-4 contel">
              <div className="card-header pb-0">
                <div className="image-md">
                  <img
                    src={webd}
                    alt="icon"
                    height="200"
                  />
                </div>
                <h2 className="h4 mt-3">{Lang.service1title}</h2>
                <p className="mb-0">{Lang.service1subtitle}</p>
              </div>
              <div className="card-body">
                <ul className="list-group">
                  <li className="list-group-item pl-0">
                    <span className="list-icon"></span>
                    <div>{Lang.service1des1}</div>
                  </li>
                  <li className="list-group-item pl-0">
                    <span className="list-icon"></span>
                    <div>{Lang.service1des2}</div>
                  </li>
                  <li className="list-group-item pl-0">
                    <span className="list-icon"></span>
                    <div>{Lang.service1des3}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0 text-center">
            <div className="card border-light p-4 contel">
              <div className="card-header pb-0">
                <div className="image-md">
                  <img
                    src={seo}
                    alt="icon"
                    height="200px"
                  />
                </div>
                <h2 className="h4 mt-3">{Lang.service2title}</h2>
                <p className="mb-0">{Lang.service2subtitle} </p>
              </div>
              <div className="card-body">
                <ul className="list-group">
                  <li className="list-group-item pl-0">
                    <span className="list-icon"></span>
                    <div>{Lang.service2des1}</div>
                  </li>
                  <li className="list-group-item pl-0">
                    <span className="list-icon"></span>
                    <div>{Lang.service2des2}</div>
                  </li>
                  <li className="list-group-item pl-0">
                    <span className="list-icon"></span>
                    <div>{Lang.service2des3} </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="services-card3" className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0 text-center">
            <div className="card border-light p-4 contel">
              <div className="card-header pb-0">
                <div className="image-md">
                  <img
                    src={social}
                    height="200px"
                    alt="icon"
                  />
                </div>
                <h2 className="h4 mt-3">{Lang.service3title}</h2>
                <p className="mb-0">{Lang.service3subtitle}</p>
              </div>
              <div className="card-body">
                <ul className="list-group">
                  <li className="list-group-item pl-0">
                    <span className="list-icon"></span>
                    <div>{Lang.service3des1}</div>
                  </li>
                  <li className="list-group-item pl-0">
                    <span className="list-icon"></span>
                    <div>{Lang.service3des2}</div>
                  </li>
                  <li className="list-group-item pl-0">
                    <span className="list-icon"></span>
                    <div>{Lang.service3des3}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="text-center">
            <PrimaryButton
              to="/"
              name={Lang.backbutton}
              classpass={`prime-button second-use questionsvg backOffer backServices`}
              img={
                <BackArrow
                  h={30}
                  w={30}
                />
              }
            />
          </div>
        </div>
      </div>
      <Footer lang={lang} />
    </section>
  );
};

export default Services;
