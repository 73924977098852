import "../_styles/style.css";
import ENG from "./lang/eng";
import SR from "./lang/sr";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "./common/logo";
import HomeIcon from "./common/_images/home";
import CatalogeIcon from "./common/_images/katalog";
import ContactIcon from "./common/_images/kontakt";
import StoreIcon from "./common/_images/trgovina";
import MenuIcon from "./common/_images/menu";
import MenuElement from "./common/menuelement";
import LaguangeDropdown from "./common/laguangeDropdown";

const Navbar = ({ lang, handleLanguageChange }) => {
  const [Lang] = lang === "sr" ? SR : ENG;
  const [visible, setVisible] = useState("");
  const [active, setActive] = useState("");
  const [showLangDrop, setShowLangDrop] = useState("");
  const [popupLeft, setPopupLeft] = useState(0);
  const langMenuRef = useRef(null);

  const reactiveMenu = navData => {
    let cls = `nav-link menu-link`;
    return navData.isActive ? (cls += "-active") : cls;
  };

  const openMenu = () => {
    setVisible(visible === "" ? "showMenu2" : "");
    setActive(active === "" ? "mb-2" : "");
  };

  const handleLaguangeShow = () => {
    setShowLangDrop(prev => (prev === "" ? "show" : ""));
  };

  const handleClickOutside = e => {
    if (showLangDrop === "show" && !langMenuRef.current?.contains(e.target)) {
      setShowLangDrop("");
    }
  };

  const handleWindowResize = () => {
    if (langMenuRef.current) {
      const searchRect = langMenuRef.current.getBoundingClientRect();
      setPopupLeft(searchRect.left);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showLangDrop]);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <React.Fragment>
      <header
        id="mainMenuLine"
        className="mainMenuLine"
      >
        <div
          id="mmwidth"
          className="px-3 py-2 bg-dark text-white"
        >
          <div className="container">
            <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
              <Logo />
              <div className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none"></div>

              <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
                <li id={visible}>
                  <NavLink
                    to="/"
                    className={reactiveMenu}
                    onClick={openMenu}
                  >
                    <MenuElement
                      name={Lang.navHome}
                      svg={HomeIcon()}
                    />
                  </NavLink>
                </li>
                <li id={visible}>
                  <NavLink
                    to="/katalog"
                    className={reactiveMenu}
                    onClick={openMenu}
                  >
                    <MenuElement
                      name={Lang.navCata}
                      svg={CatalogeIcon()}
                    />
                  </NavLink>
                </li>
                <li id={visible}>
                  <NavLink
                    to="/trgovina"
                    className={reactiveMenu}
                    onClick={openMenu}
                  >
                    <MenuElement
                      name={Lang.navShop}
                      svg={StoreIcon()}
                    />
                  </NavLink>
                </li>
                <li id={visible}>
                  <NavLink
                    to="/kontakt"
                    className={reactiveMenu}
                    onClick={openMenu}
                  >
                    <MenuElement
                      name={Lang.navContact}
                      svg={ContactIcon()}
                    />
                  </NavLink>
                </li>
                <i
                  className={`text-white phoneMenu`}
                  id="phoneMenuClick"
                  onMouseDownCapture={openMenu}
                >
                  <MenuElement
                    name=""
                    svg={MenuIcon(active)}
                  />
                </i>
              </ul>
              <div
                className="navbar-laguange
                 "
                onClick={() => handleLaguangeShow()}
                ref={langMenuRef}
                id="laguange-menu"
              >
                <img
                  src={lang === "sr" ? "/serbian.png" : "/english.png"}
                  alt="laguange"
                  className="laguange-icon cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <LaguangeDropdown
        showLangDrop={showLangDrop}
        handleLanguageChange={handleLanguageChange}
        popupLeft={popupLeft}
        Lang={Lang}
      />
    </React.Fragment>
  );
};

export default Navbar;
