import genimage from "../_images/gene.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Welcome = ({ Lang }) => {
  const topPage = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };
  return (
    <section className="pt-4 pt-md-11 welcome">
      <div className="container">
        <div className="row align-items-center welcomeel">
          <div className="col-12 col-md-5 col-lg-6 order-md-2">
            {/* {<!-- Image -->} */}
            <img
              src={genimage}
              className="img-fluid"
              alt="genetic"
            />
            {/* <img src={genegif} className="img-fluid" alt="genetic" /> */}
          </div>
          <div
            className="col-12 col-md-7 col-lg-6 order-md-1"
            data-aos="fade-up"
          >
            {/* {<!-- Heading -->} */}
            <h1 className="display-3 text-center text-md-start">
              {Lang.welcomeH1}
              <span className="text-primary">
                <br />
                ODD Genetics
              </span>{" "}
              <br />
            </h1>

            {/* {<!-- Text -->} */}
            <p className="lead text-center text-md-start text-muted mb-6 mb-lg-8">
              {Lang.welcomep1}
              <br />
              {Lang.welcomep2}
            </p>

            {/* {<!-- Buttons -->} */}
            <div className="text-center text-md-start">
              <Link
                onClick={topPage}
                to="/services"
                className="btn shadow lift me-1 button2"
              >
                {Lang.welcomebutton1}
              </Link>
              <HashLink
                to="/#offers"
                className="btn shadow lift me-1 button2 welcomebtns"
              >
                {Lang.welcomebutton2}
              </HashLink>
              <Link
                onClick={topPage}
                to="/pricecalc"
                className="btn shadow lift me-1 button2 welcomebtns"
              >
                {Lang.welcomebutton3}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
