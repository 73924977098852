import React from "react";
import logo from './_images/menuLogo.png'

const Logo = () => {
  return (
    <img className="float-md-start mb-0" id="menuLogo" src={logo} alt="OG" />
  );
};

export default Logo;
