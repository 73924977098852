import ENG from "./lang/eng";
import SR from "./lang/sr";
import phone from "./_images/phone.png";
import mail from "./_images/mail.png";
import social from "./_images/social.png";
import "../_styles/style.css";
import React, { useRef, useState } from "react";
import Typewriter from "typewriter-effect";
import Footer from "../Footer/footer";
import TypewriterString from "./tools/left-typewriterstring";
import validateEmail from "./tools/validateEmail";
import RightTypewriterString from "./tools/right-typewriterstring";
import validateNumber from "./tools/validateNumber";

const Contact = ({ lang }) => {
  const [Lang] = lang === "sr" ? SR : ENG;
  const [inputName, setInputName] = useState(null);
  const [inputNumber, setInputNumber] = useState(null);
  const [inputEmail, setInputEmail] = useState(null);
  const [inputMessage, setInputMessage] = useState(null);

  const [nameBox, setNameBox] = useState("");
  const [numberBox, setNumberBox] = useState("");
  const [emailBox, setEmailBox] = useState("");
  const [mmsgBox, setMsgBox] = useState("");

  const [success, setSuccess] = useState(false);

  const form = useRef(null);

  const handleSubmit = event => {
    event.preventDefault();

    !inputName ? setNameBox("errorBox") : setNameBox("");
    !validateNumber(inputNumber) ? setNumberBox("errorBox") : setNumberBox("");
    !validateEmail(inputEmail) ? setEmailBox("errorBox") : setEmailBox("");
    !inputMessage ? setMsgBox("errorBox") : setMsgBox("");

    if (inputName && validateNumber(inputNumber) && validateEmail(inputEmail) && inputMessage) {
      fetch("https://formsubmit.co/ajax/odd.genetics@hotmail.com", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: inputName,
          number: inputNumber,
          email: inputEmail,
          message: inputMessage,
        }),
      }).then(response => response.json());
      setSuccess(true);
      setTimeout(() => {
        form.current.reset();
      }, 10);
    }

    setTimeout(() => {
      setSuccess(false);
    }, 10000);
  };

  return (
    <React.Fragment>
      <section className="py-6">
        <div className="container">
          <div className="row justify-content-center mb-5 mb-lg-6">
            <div className="col-12 col-md-8 text-center">
              <h2 className="h1 text-white font-weight-bolder mb-4 naslov">{Lang.contacttitle}</h2>
              <p className="lead">{Lang.contactsubtitle}</p>
            </div>
          </div>

          <div className="container">
            <div className="row contact-row">
              <div className="text-center text-md-left mb-4 mb-md-0 col-md-4">
                <div className="">
                  <img
                    src={phone}
                    height="60px"
                    alt="phone number"
                  />
                </div>
                <h3 className="text-muted ">{Lang.contactnumtit}</h3>
                <p className="kontakt">
                  <strong>{Lang.contactnum}</strong>
                </p>
              </div>

              <div className="text-center text-md-left mb-4 mb-md-0 col-md-4">
                <div className="">
                  <img
                    src={mail}
                    height="60px"
                    alt="email"
                  />
                </div>
                <h3 className="text-muted">{Lang.contactmailtit}</h3>

                <p className="kontakt">
                  <strong>{Lang.contactmail}</strong>
                </p>
              </div>

              <div className="text-center text-md-left mb-4 mb-md-0 col-md-4">
                <div className="">
                  <img
                    src={social}
                    height="60px"
                    alt="social networks"
                  />
                </div>
                <h3 className="text-muted">{Lang.contactsocialtit}</h3>
                <p className="kontakt-uizradi">{Lang.contactsocial}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="row justify-content-center mb-5 mb-lg-6">
        <div className="col-12 col-md-8 text-center controw">
          <p className="lead lead2">{Lang.contactformtitle}</p>
        </div>
      </div>

      <section className="py-7 py-md-9">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6 text-center"></div>
          </div>

          <div className="row form">
            <div className="col-md-3 order-md-1 desniform">
              <Typewriter
                options={{
                  strings: [TypewriterString(Lang.animationtitle, Lang.animationh1, Lang.animationp, Lang.animationsrcandalt)],
                  autoStart: true,
                  loop: true,
                  pauseFor: 1000000,
                }}
              />
            </div>

            <div className="col-md-6 order-md-1">
              <form
                className="block-decoration-2"
                noValidate
                id="my-form"
                ref={form}
              >
                <div className="mb-3 info">
                  <label
                    className="visually-hidden"
                    htmlFor="name"
                  >
                    {Lang.contactformname}
                  </label>
                  <input
                    className={`form-control ${nameBox}`}
                    id="user_name"
                    name="name"
                    type="text"
                    placeholder={Lang.contactformname}
                    required=""
                    onBlur={e => {
                      setInputName(e.target.value);
                    }}
                    onChange={e => {
                      setInputName(e.target.value);
                      setNameBox("");
                    }}
                  />
                </div>

                <div className="mb-3 info">
                  <label
                    className="visually-hidden"
                    htmlFor="number"
                  >
                    {Lang.contactformphone}
                  </label>
                  <input
                    className={`form-control ${numberBox}`}
                    id="user_number"
                    name="number"
                    type="number"
                    placeholder={Lang.contactformphone}
                    required=""
                    onBlur={e => {
                      setInputNumber(e.target.value);
                    }}
                    onChange={e => {
                      setInputNumber(e.target.value);
                      setNumberBox("");
                    }}
                  />
                </div>

                <div className="mb-3 info">
                  <label
                    className="visually-hidden"
                    htmlFor="email"
                  >
                    {Lang.contactformmail}
                  </label>
                  <input
                    className={`form-control ${emailBox}`}
                    id="user_email"
                    name="email"
                    type="email"
                    placeholder={Lang.contactformmail}
                    required=""
                    onBlur={e => {
                      setInputEmail(e.target.value);
                    }}
                    onChange={e => {
                      setInputEmail(e.target.value);
                      setEmailBox("");
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label
                    className="visually-hidden"
                    htmlFor="message"
                  >
                    {Lang.contactformtxt}
                  </label>
                  <textarea
                    className={`form-control ${mmsgBox}`}
                    id="message"
                    name="message"
                    rows="9"
                    placeholder={Lang.contactformtxt}
                    required=""
                    onBlur={e => {
                      setInputMessage(e.target.value);
                    }}
                    onChange={e => {
                      setInputMessage(e.target.value);
                      setMsgBox("");
                    }}
                  ></textarea>
                </div>

                {success ? (
                  <div
                    className="text-center text-md-start"
                    id="success"
                  >
                    <p>{Lang.contactformsuccessmsg}</p>
                  </div>
                ) : null}

                {nameBox || numberBox || emailBox || mmsgBox ? (
                  <div
                    className="text-center text-md-start"
                    id="error"
                  >
                    <p>{Lang.contactformerrmsg}</p>
                  </div>
                ) : null}

                <div className="text-center text-md-start">
                  <button
                    className="btn btn-primary button1"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {Lang.contactformbutton}
                  </button>
                </div>
              </form>
            </div>
            <div className="col-md-3 order-md-3 desniform">
              <Typewriter
                className="2"
                options={{
                  strings: [RightTypewriterString()],

                  autoStart: true,
                  loop: true,
                  pauseFor: 1000000,
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer lang={lang} />
    </React.Fragment>
  );
};

export default Contact;
