const ENG = [
  {
    //Home - Welcome section
    welcomeH1: `Welcome to`,
    welcomep1: `Our fusion of innovative web development and creative design makes your online world unique and efficient.`,
    welcomep2: `Whether you need an informative website, a dynamic site with changing content, or an online shop, we are here to turn your vision into reality.`,
    welcomebutton1: `Services`,
    welcomebutton2: `Offers`,
    welcomebutton3: `Survey`,
    //Home - Services
    services1h3: `Website Development`,
    services1p: `We provide a diverse range of unique websites tailored to your specific requirements.`,
    services2h3: `Search Engine Optimization`,
    services2p: `Optimizing your site to be easily found by other users on the internet.`,
    services3h3: `Social Media`,
    services3p: `Integrating your website with existing social media platforms, as well as the ability to create and maintain new ones.`,
    //Home - Offers
    offerstitle1: `Highlighted Offers:`,
    offersinfotitle: `Informative Website`,
    offersinfoprice: `100`,
    offersinfosign: `$`,
    offersinfob1: `Custom Design:`,
    offersinfospan1: `Individual design tailored to your brand and goals.`,
    offersinfob2: `Simple Navigation:`,
    offersinfospan2: `Intuitive site structure for easy user navigation.`,
    offersinfob3: `Search Engine Optimization:`,
    offersinfospan3: `Implementation of basic SEO techniques for better search engine rankings.`,
    offersinfob4: `Social Media Integration:`,
    offersinfospan4: `Enabling content sharing on social media and integration with various platforms to expand online presence.`,
    offerscmstitle: `Dynamic Content Website`,
    offerscmsprice: `200`,
    offerscmssign: `$`,
    offerscmsb1: `CMS Integration:`,
    offerscmsspan1: `Utilization of modern Content Management System tools for easy content management and updates.`,
    offerscmsb2: `Dynamic Elements:`,
    offerscmsspan2: `Implementation of interactive and dynamic elements to enhance user experience.`,
    offerscmsb3: `Fast Content Accessibility:`,
    offerscmsspan3: `Achieving fast content accessibility and loading to maintain visitor attention and improve user experience.`,
    offerscmsb4: `User Training:`,
    offerscmsspan4: `Providing training so you can independently manage and maintain your website.`,
    offersshoptitle: `Online Shop`,
    offersshopprice: `400`,
    offersshopsign: `$`,
    offersshopb1: `E-commerce Platform:`,
    offersshopspan1: `Implementation of secure platforms tailored to online commerce.`,
    offersshopb2: `User Experience:`,
    offersshopspan2: `Creating an intuitive interface for easy navigation and product browsing.`,
    offersshopb3: `Transaction Security:`,
    offersshopspan3: `Integration of security protocols to protect user data and transactions.`,
    offersshopb4: `Inventory Management:`,
    offersshopspan4: `System for easy tracking and management of product inventory.`,
    from: `starting from `,
    moreinfo: `More Information`,
    commingsoon: `Coming Soon`,
    offerstitle21: `Not sure which option is best for you?`,
    offerstitle22: `Go through our short questionnaire:`,
    surveyButton: `Survey`,
    //Home - Catalogue
    cataloguetitle: "Or check out our catalogue:",
    cataloguebutton: "Catalogue",
    //Home - Contact
    contacttitle: "If you already know what you want, contact us now:",
    //Home - Platforms
    platformstitle: "Platforms we use:",
    contactbutton: `Contact`,

    //Offers
    
    
  },
];

export default ENG;
