import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import React, { useState } from "react";
import { Routes, Route } from "react-router";
import Navbar from "./components/a-Navbar/Navbar";
import Home from "./components/b-Home/Home";
import Catalouge from "./components/c-Catalouge/katalog";
import Shop from "./components/d-Shop/shop";
import Contact from "./components/e-Contact/contact";
import Terms from "./components/Footer/terms";
import Policy from "./components/Footer/policy";

import Services from "./components/b-Home/home-components/services/services";
import PriceCalc from "./components/b-Home/home-components/pricecalc/pricecalc";

import InfoSite from "./components/b-Home/home-components/packages/infosite";
import ContentSite from "./components/b-Home/home-components/packages/contentsite";
//import WebShopSite from './components/b-Home/home-components/packages/webshopsite';

const App = () => {
  disableReactDevTools();

  const [lang, setLang] = useState(() => {
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage || "en";
  });

  const handleLanguageChange = selectedLanguage => {
    setLang(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
  };

  return (
    <React.Fragment>
      <Navbar
        lang={lang}
        handleLanguageChange={handleLanguageChange}
      />
      <Routes>
        <Route
          path="/"
          exact
          element={<Home lang={lang} />}
        />
        <Route
          path="/services"
          exact
          element={<Services lang={lang} />}
        />
        <Route
          path="/pricecalc"
          exact
          element={<PriceCalc lang={lang} />}
        />
        <Route
          path="/katalog"
          exact
          element={<Catalouge lang={lang} />}
        />
        <Route
          path="/trgovina"
          exact
          element={<Shop lang={lang} />}
        />
        <Route
          path="/kontakt"
          exact
          element={<Contact lang={lang} />}
        />
        <Route
          path="/uslovi-koriscenja"
          exact
          element={<Terms lang={lang} />}
        />
        <Route
          path="/politika-privatnosti"
          exact
          element={<Policy lang={lang} />}
        />
        <Route
          path="/info-site"
          exact
          element={<InfoSite lang={lang} />}
        />
        <Route
          path="/content-site"
          exact
          element={<ContentSite lang={lang} />}
        />
        {/* 
        <Route
            path='/web-shop-site'
            exact
            element={<WebShopSite />}
        /> */}
        <Route
          path="*"
          exact
          element={<Home lang={lang} />}
        />
      </Routes>
    </React.Fragment>
  );
};

export default App;
