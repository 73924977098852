import React from "react";
//import { Link } from 'react-router-dom';
//import TopPage from '../../../../utils/topPage';
import Small from "../../../../utils/small";
import SvgCheck from "../../_images/check";
import { Link } from "react-router-dom";
import TopPage from "../../../../utils/topPage";

export default function CMSPackage({ Lang }) {
  return (
    <div className="col-lg-3 p-md-0 mb-lg-auto mb-4 z-index-2 kontentSajt">
      <div className="card">
        <div className="card-header text-center pt-4 pb-3">
          <h6 className="text-dark opacity-8 text mb-0 mt-2">{Lang.offerscmstitle}</h6>

          <h1 className="font-weight-bolder price">
            <Small text={Lang.from} />
            {Lang.offerscmsprice}
            <small>
              <small>
                <small>{Lang.offerscmssign}</small>
              </small>
            </small>
          </h1>
        </div>
        <div className="card-body mx-auto pt-0">
          <div className="justify-content-start d-flex px-2 py-1">
            <div>
              <SvgCheck />
            </div>
            <div className="ps-2">
              <span className="text-sm">
                <b className="BoldColor">{Lang.offerscmsb1}</b> <br /> {Lang.offerscmsspan1}
              </span>
            </div>
          </div>
          <div className="justify-content-start d-flex px-2 py-1">
            <div>
              <SvgCheck />
            </div>
            <div className="ps-2">
              <span className="text-sm">
                <b className="BoldColor">{Lang.offerscmsb2}</b> <br />
                {Lang.offerscmsspan2}
              </span>
            </div>
          </div>

          <div className="justify-content-start d-flex px-2 py-1">
            <div>
              <SvgCheck />
            </div>
            <div className="ps-2">
              <span className="text-sm">
                <b className="BoldColor">{Lang.offerscmsb3}</b> <br /> {Lang.offerscmsspan3}
              </span>
            </div>
          </div>

          <div className="justify-content-start d-flex px-2 py-1">
            <div>
              <SvgCheck />
            </div>
            <div className="ps-2">
              <span className="text-sm">
                <b className="BoldColor">{Lang.offerscmsb4}</b> <br /> {Lang.offerscmsspan4}
              </span>
            </div>
          </div>
        </div>
        <div className="card-footer pt-0">
          <Link
            onClick={TopPage}
            to="/content-site"
            className="btn button1 w-100 bg-gradient-dark mb-0 moreinfo"
          >
            {Lang.moreinfo}
          </Link>
        </div>
      </div>
    </div>
  );
}
