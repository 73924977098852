const ENG = [
  {
    //Footer
    footercontact: "Contact",
    footerterms: "Terms of Use",
    footerpolicy: "Privacy Policy",
    
  },
];

export default ENG;
