const IzradaSVG = () => {
    return (
        <svg
            x='0px'
            y='0px'
            viewBox='0 50 415 415'
            width='60px'
            height='40px'
        >
            <rect
                y='105.852'
                fill='#0dcaf0'
                opacity='0.3'
                width='512'
                height='366.496'
            />
            <rect
                y='459.596'
                width='512'
                height='12.768'
            />
            <path d='M0,119.372V55.636c0-8.8,7.2-16,16-16h479.96c8.8,0,16,7.2,16,16v63.728H0V119.372z' />
            <polygon
                fill='#0dcaf0'
                opacity='0.4'
                points='471.624,101.508 171.536,101.508 200.24,57.508 471.624,57.508 '
            />
            <circle
                fill='#E04F5F'
                cx='53.904'
                cy='78.908'
                r='13.608'
            />
            <circle
                fill='#F2B851'
                cx='89.976'
                cy='78.908'
                r='13.608'
            />
            <g>
                <circle
                    fill='#25B6D2'
                    cx='126.056'
                    cy='78.908'
                    r='13.608'
                />
                <path
                    fill='#FFFFF'
                    d='M215.624,71.244l1.912,8.312c0.416,1.952,0.832,3.944,1.168,5.936h0.08
		c0.376-1.992,0.976-4.024,1.504-5.896l2.368-8.352h3.76l2.296,8.128c0.56,2.144,1.048,4.136,1.464,6.128h0.08
		c0.296-1.992,0.712-3.984,1.2-6.128l2.032-8.128h4.544l-5.752,18.312h-4.288l-2.176-7.448c-0.56-1.912-0.976-3.648-1.392-5.864
		h-0.08c-0.376,2.256-0.856,4.064-1.392,5.864l-2.328,7.448h-4.328l-5.416-18.312H215.624z'
                />
                <path
                    fill='#FFFFF'
                    d='M244.392,71.244l1.912,8.312c0.416,1.952,0.832,3.944,1.168,5.936h0.08
		c0.376-1.992,0.976-4.024,1.504-5.896l2.368-8.352h3.76l2.296,8.128c0.56,2.144,1.048,4.136,1.464,6.128h0.08
		c0.296-1.992,0.712-3.984,1.2-6.128l2.032-8.128h4.544l-5.752,18.312h-4.288l-2.176-7.448c-0.56-1.912-0.976-3.648-1.392-5.864
		h-0.08c-0.376,2.256-0.856,4.064-1.392,5.864l-2.328,7.448h-4.328l-5.416-18.312H244.392z'
                />
                <path
                    fill='#FFFFF'
                    d='M273.16,71.244l1.912,8.312c0.416,1.952,0.832,3.944,1.168,5.936h0.08
		c0.376-1.992,0.976-4.024,1.504-5.896l2.368-8.352h3.76l2.296,8.128c0.56,2.144,1.048,4.136,1.464,6.128h0.08
		c0.296-1.992,0.712-3.984,1.2-6.128l2.032-8.128h4.544l-5.752,18.312h-4.296l-2.176-7.448c-0.56-1.912-0.976-3.648-1.392-5.864
		h-0.08c-0.376,2.256-0.856,4.064-1.392,5.864l-2.328,7.448h-4.328l-5.416-18.312H273.16z'
                />
                <path
                    fill='#FFFFF'
                    d='M296.312,87.004c0-1.736,1.2-2.968,2.856-2.968c1.696,0,2.856,1.2,2.856,2.968
		c0,1.688-1.128,2.968-2.856,2.968C297.488,89.972,296.312,88.692,296.312,87.004z'
                />
            </g>
            <path
                d='M436.56,91.556c-7.8,0-14.144-6.344-14.144-14.152c0-7.8,6.344-14.144,14.144-14.144c7.808,0,14.152,6.344,14.152,14.144
	C450.712,85.212,444.368,91.556,436.56,91.556z M436.56,67.268c-5.592,0-10.144,4.544-10.144,10.144s4.544,10.152,10.144,10.152
	s10.152-4.552,10.152-10.152S442.16,67.268,436.56,67.268z'
            />
            <rect
                x='442.795'
                y='87.571'
                transform='matrix(-0.7071 -0.7071 0.7071 -0.7071 702.6617 470.1938)'
                width='11.832'
                height='4'
            />
            <polygon points='342.688,464.212 169.312,464.212 193.312,445.964 318.688,445.964 ' />
            <g>
                <path
                    fill='#FFFFFF'
                    d='M104.176,312.564c0-8.344,5.808-14.328,13.792-14.328c8.168,0,13.784,5.808,13.784,14.328
		c0,8.168-5.44,14.328-13.784,14.328C109.808,326.892,104.176,320.732,104.176,312.564z'
                />
                <path
                    fill='#FFFFFF'
                    d='M149.704,262.86c0-10.16-0.176-18.68-0.72-26.304h19.584l1.096,13.24h0.536
		c3.816-6.888,13.424-15.232,28.12-15.232c15.424,0,31.384,9.976,31.384,37.912V324.9h-22.312v-49.88
		c0-12.696-4.72-22.312-16.864-22.312c-8.888,0-15.064,6.352-17.416,13.064c-0.728,1.992-0.904,4.712-0.904,7.248v51.88h-22.496
		v-62.04H149.704z'
                />
                <path
                    fill='#FFFFFF'
                    d='M269.224,286.988c0.544,15.968,13.064,22.856,27.208,22.856c10.344,0,17.784-1.456,24.496-3.992
		l3.264,15.424c-7.616,3.08-18.144,5.44-30.832,5.44c-28.664,0-45.528-17.592-45.528-44.624c0-24.488,14.872-47.52,43.168-47.52
		c28.656,0,38.096,23.576,38.096,42.992c0,4.168-0.368,7.44-0.728,9.432h-59.144V286.988z M308.048,271.388
		c0.176-8.16-3.448-21.584-18.32-21.584c-13.784,0-19.584,12.52-20.496,21.584H308.048z'
                />
                <path
                    fill='#FFFFFF'
                    d='M372.608,211.348v25.208h21.224v16.696h-21.224v39c0,10.704,2.904,16.32,11.432,16.32
		c3.816,0,6.712-0.536,8.712-1.088l0.36,17.056c-3.264,1.264-9.072,2.168-16.144,2.168c-8.168,0-15.064-2.72-19.232-7.256
		c-4.712-5.08-6.888-13.056-6.888-24.664v-41.536h-12.696v-16.696h12.696v-19.952L372.608,211.348z'
                />
            </g>
        </svg>
    );
};

export default IzradaSVG;
