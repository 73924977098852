import React, { useEffect, useState } from "react";

const LaguangeDropdown = ({ showLangDrop, handleLanguageChange, popupLeft, Lang }) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div
      className={`dropdown-lang-menu laguange ${showLangDrop}`}
      style={{ display: isVisible ? "block" : "none", left: `${popupLeft > 945 ? popupLeft - 30 : popupLeft - 60}px` }}
    >
      <ul>
        <li
          className="cursor-pointer"
          onClick={() => {
            handleLanguageChange("en");
          }}
        >
          <img
            src="/english.png"
            alt="English laguange"
            className="laguange-drop-icon"
          />
          <span>{Lang.eng}</span>
        </li>
        <li
          className="cursor-pointer"
          onClick={() => {
            handleLanguageChange("sr");
          }}
        >
          <img
            src="/serbian.png"
            alt="Serbian laguange"
            className="laguange-drop-icon"
          />
          <span>{Lang.sr}</span>
        </li>
      </ul>
    </div>
  );
};

export default LaguangeDropdown;
