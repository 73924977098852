import React from "react";
import { Link } from "react-router-dom";
import SR from "./lang/sr";
import ENG from "./lang/eng";

const Footer = ({ lang }) => {

  const [Lang] = lang === "sr" ? SR : ENG;

  const topPage = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  };

  return (
    <div
      className="footer py-4 d-flex flex-lg-column"
      id="kt_footer"
    >
      <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div className="text-dark order-2 order-md-1 footerL">
          <span className="footerTextL">©2021 </span>
          <i className="footerTextL">OddGenetics</i>
        </div>
        <ul className="order-1 footerR">
          <li className="liFooter">
            <Link
              to="/kontakt"
              className="linkFooter px-2"
              onClick={()=>{topPage()}}
            >
              {Lang.footercontact}
            </Link>
          </li>

          <li className="liFooter">
            <Link
              to="/uslovi-koriscenja"
              className="linkFooter px-2"
              onClick={()=>{topPage()}}
            >
              {Lang.footerterms}
            </Link>
          </li>
          <li className="liFooter">
            <Link
              to="/politika-privatnosti"
              className="linkFooter px-2"
              onClick={()=>{topPage()}}
            >
              {Lang.footerpolicy}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
