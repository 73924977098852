import React from "react";
import { Link } from "react-router-dom";
import TopPage from "../../../../utils/topPage";
import Small from "../../../../utils/small";
import SvgCheck from "../../_images/check";

export default function InfoPackage({ Lang }) {
  return (
    <div className="col-lg-3 mb-lg-auto mb-4 my-auto p-md-0 ms-auto infoSajt">
      <div className="card card-offer border-radius-top-end-lg-0 border-radius-bottom-end-lg-0">
        <div className="card-header text-center pt-4 pb-3">
          <h6 className="text-dark opacity-8 mb-0">{Lang.offersinfotitle}</h6>
          <h1 className="font-weight-bolder price">
            <Small text={Lang.from} />
            {Lang.offersinfoprice}
            <small>
              <small>
                <small>{Lang.offersinfosign}</small>
              </small>
            </small>
          </h1>
        </div>
        <div className="card-body mx-auto pt-0">
          <div className="justify-content-start d-flex px-2 py-1">
            <div>
              <SvgCheck />
            </div>
            <div className="ps-2">
              <span className="text-sm">
                <b className="BoldColor">{Lang.offersinfob1}</b> <br />
                {Lang.offersinfospan1}
              </span>
            </div>
          </div>

          <div className="justify-content-start d-flex px-2 py-1">
            <div>
              <SvgCheck />
            </div>
            <div className="ps-2">
              <span className="text-sm">
                <b className="BoldColor">{Lang.offersinfob2}</b> <br />
                {Lang.offersinfospan2}
              </span>
            </div>
          </div>
          <div className="justify-content-start d-flex px-2 py-1">
            <div>
              <SvgCheck />
            </div>
            <div className="ps-2">
              <span className="text-sm">
                <b className="BoldColor">{Lang.offersinfob3}</b> <br /> {Lang.offersinfospan3}
              </span>
            </div>
          </div>
          <div className="justify-content-start d-flex px-2 py-1">
            <div>
              <SvgCheck />
            </div>
            <div className="ps-2">
              <span className="text-sm">
                <b className="BoldColor">{Lang.offersinfob4}</b> <br /> {Lang.offersinfospan4}
              </span>
            </div>
          </div>
        </div>
        <div className="card-footer pt-0">
          <Link
            onClick={TopPage}
            to="/info-site"
            className="btn button1 w-100 bg-gradient-dark mb-0 moreinfo"
          >
            {Lang.moreinfo}
          </Link>
        </div>
      </div>
    </div>
  );
}
