import React from "react";

const MenuElement = (props) => {
  return (
    <React.Fragment>
      {props.svg}
      <span className="menuText">{props.name}</span>
    </React.Fragment>
  );
};

export default MenuElement;
