import React, { Component } from "react";
import nextjs from "./_images/nextjs.png";
import react from "./_images/react.png";
import sass from "./_images/sass.png";
import node from "./_images/node.png";

const Platforms = ({ Lang }) => {
  return (
    <section className="py-8 py-md-11 platforms">
      <div className="text-center">
        <p
          id="ponuda"
          className="lead medjutekst"
        >
          {Lang.platformstitle}
        </p>
      </div>
      <div className="container text-center">
        <div className="row">
          <div className="col">
            <img
              className="logoNext"
              src={nextjs}
              alt="next.js logo"
            />
          </div>
          <div className="col">
            <img
              className="logoReact"
              src={react}
              alt="react.js logo"
            />
          </div>
          <div className="col">
            <img
              className="logoSass"
              src={sass}
              alt="sass logo"
            />
          </div>
          <div className="col">
            <img
              className="logoNode"
              src={node}
              alt="node.js logo"
            />
          </div>
        </div>
      </div>
      {/* <div className='container text-center cont2'>
                    <div className='row'>
                        <div className='col'></div>
                        <div className='col'>
                            <img
                                className='logoFirebase'
                                src={firebase}
                                alt='firebase logo'
                            />
                        </div>
                        <div className='col'></div>
                        <div className='col'>
                            <img
                                className='logoNode'
                                src={node}
                                alt='node.js logo'
                            />
                        </div>
                        <div className='col'></div>
                    </div>
                </div> */}
    </section>
  );
};

export default Platforms;
