import InfoPackage from "./packages-section/infoPackage";
import CMSPackage from "./packages-section/cmsPackage";
import ShopPackage from "./packages-section/shopPackage";
import PrimaryButton from "../../../utils/button";
import QuestionSVG from "../_images/question";

const OfferPackages = ({ Lang }) => {
  return (
    <section className="py-8 py-md-11">
      <div className="text-center">
        <p
          id="offers"
          className="lead medjutekst"
        >
          {Lang.offerstitle1}
        </p>
      </div>

      <div className="container">
        <div className="row packages-row">
          {/*  Info sajt */}
          <InfoPackage Lang={Lang}/>

          {/* Sajt promjenjivog sadržaja */}
          <CMSPackage Lang={Lang}/>

          {/* Internet trgovina */}
          <ShopPackage Lang={Lang}/>
        </div>
      </div>
      <div className="text-center">
        <p className="lead medjutekst">
          {Lang.offerstitle21}
          <br />
          {Lang.offerstitle22}
        </p>

        <PrimaryButton
          to="/pricecalc"
          name={Lang.surveyButton}
          classpass={`prime-button questionsvg`}
          img={
            <QuestionSVG
              h={40}
              w={40}
            />
          }
        />
      </div>
    </section>
  );
};

export default OfferPackages;
