const SR = [
  {
    servicestitle: `Usluge`,
    servicessubtitle: `Vršimo usluge izrade i promocije web stranica kao i njihovo održavanje.`,

    service1title: `Izrada web stranice`,
    service1subtitle: `Kreiramo stranice u zavisnosti od vaših potreba i želja`,
    service1des1: `Informativne i promotivne web stranice`,
    service1des2: `Stranice sa kontent menadžmentom`,
    service1des3: `Internet trgovine`,

    service2title: `SEO`,
    service2subtitle: `Optimizacija web stranice za pretraživače`,
    service2des1: `Povećanje količine i kvalitete prometa na vašoj web stranici`,
    service2des2: `Poboljšanje pozicije u listi rezultata pretrage`,
    service2des3: `Off-site optimizacija`,

    service3title: `Društvene mreže`,
    service3subtitle: `Povežite i dijelite lako i brzo sadržaj između vašeg sajta i društvenih mreža`,
    service3des1: `Povezivanje sa već postojećim društvenim mrežama`,
    service3des2: `Otvaranje novih društvenih mreža`,
    service3des3: `Održavanje sadržaja na društvenim mrežama`,

    backbutton: `Nazad`,
  },
];

export default SR;
