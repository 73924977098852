const SR = [
  {
    //Home - Welcome section
    welcomeH1: `Dobrodošli na`,
    welcomep1: `Naš spoj inovativnog web developmenta i kreativnog
dizajna čini vaš online svijet jedinstvenim i
efikasnim.`,
    welcomep2: `Bez obzira trebate li informativan
sajt, dinamički sajt s promjenjivim sadržajem ili
web shop, mi smo tu da vašu viziju pretvorimo
u stvarnost.`,
    welcomebutton1: `Usluge`,
    welcomebutton2: `Ponude`,
    welcomebutton3: `Upitnik`,
    //Home - Services
    services1h3: `Izrada web stranice`,
    services1p: `Pružamo raznovrsnu ponudu jedinstvenih web stranica
koje ce biti prilagođene vašim specifičnim
zahtevima.`,
    services2h3: `Optimizacija za pretraživače`,
    services2p: `Optimizacija sajta kako bi bio lako pronađen od
strane drugih korisnika na internetu.`,
    services3h3: `Društvene mreže`,
    services3p: `Integracija vaše web stranice sa postojećim
društvenim mrežama, kao i mogućnost stvaranja i
održavanja novih.`,
    //Home - Offers
    offerstitle1: `Iz ponude izdvajamo:`,
    offersinfotitle: `Informativni sajt`,
    offersinfoprice: `200`,
    offersinfosign: `KM`,
    offersinfob1: `Prilagođen dizajn:`,
    offersinfospan1: `Individualni dizajn prilagođen vašem
                        brendu i ciljevima.`,
    offersinfob2: `Jednostavna navigacija:`,
    offersinfospan2: `Intuitivna struktura sajta za lako
                        snalaženje korisnika.`,
    offersinfob3: `Optimizacija za pretraživače:`,
    offersinfospan3: `Implementacija osnovnih SEO
                        tehnika za bolje rangiranje na
                        tražilicama.`,
    offersinfob4: `Integracija društvenih mreža:`,
    offersinfospan4: `Omogućavanje dijeljenja
                        sadržaja na društvenim mrežama i
                        integracija s različitim platformama
                        za proširenje online prisutnosti.`,
    offerscmstitle: `Sajt promjenjivog sadržaja`,
    offerscmsprice: `400`,
    offerscmssign: `KM`,
    offerscmsb1: `CMS Integracija:`,
    offerscmsspan1: `Korištenje modernih Content Management
                                System alata za lako upravljanje i ažuriranje
                                sadržaja.`,
    offerscmsb2: `Dinamički elementi:`,
    offerscmsspan2: `Implementacija interaktivnih i dinamičkih
                                elemenata za poboljšanje korisničkog iskustva.`,
    offerscmsb3: `Brza dostupnost sadržaja:`,
    offerscmsspan3: `Postizanje brze dostupnosti i učitavanja
                                sadržaja kako bi se održala pažnja posjetitelja
                                i poboljšalo korisničko iskustvo.`,
    offerscmsb4: `Korisnička obuka:`,
    offerscmsspan4: `Pružanje obuke kako biste samostalno
                                mogli upravljati i održavati svoj sajt.`,
    offersshoptitle: `Internet trgovina`,
    offersshopprice: `800`,
    offersshopsign: `KM`,
    offersshopb1: `E-trgovinska platforma:`,
    offersshopspan1: `Implementacija sigurnih platformi prilagođenih online trgovini.`,
    offersshopb2: `Korisničko iskustvo:`,
    offersshopspan2: `Kreiranje intuitivnog sučelja za jednostavnu navigaciju i pregled proizvoda.`,
    offersshopb3: `Sigurnost transakcija:`,
    offersshopspan3: `Integracija sigurnosnih protokola za zaštitu korisničkih podataka i
                transakcija.`,
    offersshopb4: `Upravljanje inventarom:`,
    offersshopspan4: `Sistem za lako praćenje i upravljanje inventarom proizvoda.`,
    from: `već od `,
    moreinfo: `Više informacija`,
    commingsoon: `Dolazi uskoro`,
    offerstitle21: `Niste sigurni koja je opcija najbolja za vas?`,
    offerstitle22: `Prođite kroz naš kratki upitnik:`,
    surveyButton: `Upitnik`,
    //Home - Catalogue
    cataloguetitle: `Ili pogledajte naš katalog:`,
    cataloguebutton: `Katalog`,
    //Home - Contact
    contacttitle: `Ukoliko već znate šta želite, kontaktirajte nas odmah:`,
    //Home - Platforms
    platformstitle: `Platforme koje koristimo:`,
    contactbutton: `Kontakt`,
  },
];

export default SR;
