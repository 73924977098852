import ENG from "./lang/eng";
import SR from "./lang/sr";
import SvgCheck from "../../_images/check";
import Arrow from "../../_images/arrow";
import PrimaryButton from "../../../../utils/button";
import BackArrow from "../../../_styles/icons/back-arrow";
import ContactIcon from "../../../a-Navbar/common/_images/kontakt";
import Footer from "../../../Footer/footer";
import { useState } from "react";
import PresenationVideo from "./presenationVideo";
import Slider from "../../../../common/slider";

const ContentSite = ({ video, lang }) => {
  const [Lang] = lang === "sr" ? SR : ENG;
  const [top, setTop] = useState(null);
  const [presentation, setPresentation] = useState();
  const slidercont = [
    "/content-slider/1.png",
    "/content-slider/2.png",
    "/content-slider/3.png",
    "/content-slider/4.png",
    "/content-slider/5.png",
    "/content-slider/6.png",
    "/content-slider/7.png",
    "/content-slider/8.png",
    "/content-slider/9.png",
  ];

  const handleMedia = () => {
    document.body.style.overflow = "hidden";
    setTop(window.scrollY);
  };
  return (
    <section className="pt-4 pt-md-11 pogodnosti-naslov">
      <div className="container">
        <div className="col-12 order-md-1">
          <h1 className="display-3 text-center text-md-start">{Lang.cmstitle}</h1>
          <p className="lead text-center text-md-start text-muted mb-6 mb-lg-8">{Lang.cmssubtitle}</p>
        </div>
        <div className="row align-items-center ">
          <div className="col-12 col-md-7 col-lg-6 info1">
            <p className="lead text-center text-md-start sadrzaj">{Lang.cmsincludes}</p>

            <section className="pogodnosti">
              {/* CMS 1*/}
              <div className="justify-content-start d-flex px-2 py-1 py-1">
                <div>
                  <SvgCheck />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.cms1main}</span>
                </div>
              </div>

              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.cms1submain1}</span>
                </div>
              </div>
              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.cms1submain2}</span>
                </div>
              </div>
              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.cms1submain3}</span>
                </div>
              </div>
              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.cms1submain4}</span>
                </div>
              </div>

              <br />
              {/* CMS 2*/}
              <div className="justify-content-start d-flex px-2 py-1 py-1">
                <div>
                  <SvgCheck />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.cms3main}</span>
                </div>
              </div>

              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.cms3submain1}</span>
                </div>
              </div>
              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.cms3submain2}</span>
                </div>
              </div>
              <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment">
                <div>
                  <Arrow />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.cms3submain3}</span>
                </div>
              </div>

              <br />
              {/* CMS 3*/}
              <div className="justify-content-start d-flex px-2 py-1 py-1">
                <div>
                  <SvgCheck />
                </div>
                <div className="ps-2">
                  <span className="text-sm">{Lang.cms2main}</span>
                </div>
              </div>
              <div className="functionality-prev-cont">
                <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment functionality-prev">
                  <div>
                    <img
                      src="/thumbs/Website.png"
                      onClick={() => {
                        handleMedia();
                        setPresentation("/videos/1.mp4");
                      }}
                    />
                  </div>
                  <div className="ps-2">
                    <span className="text-sm package-example-button">{Lang.cms2submain1}</span>
                  </div>
                </div>
                <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment functionality-prev">
                  <div>
                    <img
                      src="/thumbs/Team.png"
                      onClick={() => {
                        handleMedia();
                        setPresentation("/videos/2.mp4");
                      }}
                    />
                  </div>
                  <div className="ps-2">
                    <span className="text-sm package-example-button">{Lang.cms2submain2}</span>
                  </div>
                </div>
                <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment functionality-prev">
                  <div>
                    <img
                      src="/thumbs/Team.png"
                      onClick={() => {
                        handleMedia();
                        setPresentation("/videos/3.mp4");
                      }}
                    />
                  </div>
                  <div className="ps-2">
                    <span className="text-sm package-example-button">{Lang.cms2submain3}</span>
                  </div>
                </div>
                <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment functionality-prev">
                  <div>
                    <img
                      src="/thumbs/File.png"
                      onClick={() => {
                        handleMedia();
                        setPresentation("/videos/4.mp4");
                      }}
                    />
                  </div>
                  <div className="ps-2">
                    <span className="text-sm package-example-button">{Lang.cms2submain4}</span>
                  </div>
                </div>
                <div className="justify-content-start d-flex px-2 py-1 py-1 sub-comment functionality-prev">
                  <div>
                    <img
                      src="/thumbs/Supp.png"
                      onClick={() => {
                        handleMedia();
                        setPresentation("/videos/5.mp4");
                      }}
                    />
                  </div>
                  <div className="ps-2">
                    <span className="text-sm package-example-button">{Lang.cms2submain5}</span>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="col-12 col-md-7 col-lg-6 order-md-1 info2">
        
            <Slider slidercont={slidercont} />
            <div className="row buttonPackages">
              <div className="col packagesButL">
                <PrimaryButton
                  to="/"
                  name={Lang.backbutton}
                  classpass={`prime-button backPackage`}
                  img={
                    <BackArrow
                      h={20}
                      w={20}
                    />
                  }
                />
              </div>
              <div className="col packagesButR">
                <PrimaryButton
                  to="/kontakt"
                  name={Lang.contactbutton}
                  classpass={`prime-button contactPackage`}
                  img={<ContactIcon />}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row buttonPackagesPhone">
          <div className="col packagesButL">
            <PrimaryButton
              to="/"
              name={Lang.backbutton}
              classpass={`prime-button backPackage`}
              img={
                <BackArrow
                  h={20}
                  w={20}
                />
              }
            />
          </div>
          <div className="col packagesButR">
            <PrimaryButton
              to="/kontakt"
              name={Lang.contactbutton}
              classpass={`prime-button contactPackage`}
              img={<ContactIcon />}
            />
          </div>
        </div>
      </div>
      {presentation && (
        <PresenationVideo
          top={top}
          presentation={presentation}
          setPresentation={setPresentation}
        />
      )}
      <Footer lang={lang} />
    </section>
  );
};

export default ContentSite;
