const SR = [
  {
    contacttitle: `Kontakt`,
    contactsubtitle: `Kontaktirajte nas putem:`,
    contactnumtit: `Broj telefona:`,
    contactnum: `+387 66 618 640`,
    contactmailtit: `Email:`,
    contactmail: `odd.genetics@hotmail.com`,
    contactsocialtit: `Društvene mreže:`,
    contactsocial: `U izradi`,
    contactformtitle: `Ili ispunite i pošaljite obrazac a mi ćemo potom stupiti u kontakt sa vama :`,
    contactformname: `Ime i prezime`,
    contactformphone: `Broj telefona`,
    contactformmail: `E-mail`,
    contactformtxt: `Poruka`,
    contactformsuccessmsg: `Poruka uspješno poslana!`,
    contactformerrmsg: `Molimo vas da ispravno popunite neophodna polja!`,
    contactformbutton: `Pošalji`,

    animationtitle: `Vaša web stranica`,
    animationh1: `Naslov`,
    animationp: `Tekst`,
    animationsrcandalt: `slika`,
  },
];

export default SR;
