const SR = [
  {
    //Footer
    footercontact:`Kontakt`,
    footerterms:`Uslovi koristenja`,
    footerpolicy:`Politika privatnosti`,
  },
];

export default SR;
