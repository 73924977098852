import React from "react";
//import { Link } from 'react-router-dom';
//import TopPage from '../../../../utils/topPage';
import Small from "../../../../utils/small";
import SvgCheck from "../../_images/check";

export default function ShopPackage({ Lang }) {
  return (
    <div className="col-lg-3 mb-lg-auto mb-4 my-auto p-md-0 me-auto shopSajt">
      <div className="card border-radius-top-start-lg-0 border-radius-bottom-start-lg-0 shopSajtHeight">
        <div className="card-header text-center pt-4 pb-2">
          <h6 className="text-dark opacity-8 text mb-0">{Lang.offersshoptitle}</h6>
          <h1 className="font-weight-bolder price">
            <Small text={Lang.from} />
            {Lang.offersshopprice}
            <small>
              <small>
                <small>{Lang.offersshopsign}</small>
              </small>
            </small>
          </h1>
        </div>
        <div className="card-body mx-auto pt-0">
          <div className="justify-content-start d-flex px-2 py-1 py-1">
            <div>
              <SvgCheck />
            </div>
            <div className="ps-2">
              <span className="text-sm">
                <b className="BoldColor">{Lang.offersshopb1}</b> <br /> {Lang.offersshopspan1}
              </span>
            </div>
          </div>

          <div className="justify-content-start d-flex px-2 py-1 py-1">
            <div>
              <SvgCheck />
            </div>
            <div className="ps-2">
              <span className="text-sm">
                <b className="BoldColor">{Lang.offersshopb2}</b> <br /> {Lang.offersshopspan2}
              </span>
            </div>
          </div>

          <div className="justify-content-start d-flex px-2 py-1 py-1">
            <div>
              <SvgCheck />
            </div>
            <div className="ps-2">
              <span className="text-sm">
                <b className="BoldColor">{Lang.offersshopb3}</b> <br /> {Lang.offersshopspan3}
              </span>
            </div>
          </div>
          <div className="justify-content-start d-flex px-2 py-1 py-1">
            <div>
              <SvgCheck />
            </div>
            <div className="ps-2">
              <span className="text-sm">
                <b className="BoldColor">{Lang.offersshopb4}</b> <br /> {Lang.offersshopspan4}
              </span>
            </div>
          </div>
        </div>
        <div className="card-footer pt-0">
          <p className="btn button3 w-100 bg-gradient-dark mb-0 moreinfo">{Lang.commingsoon}</p>
        </div>
      </div>
    </div>
  );
}
