import "../../../_styles/style.css";
import React, { useState } from "react";
import ENG from "./lang/eng";
import SR from "./lang/sr";
import Q1 from "./qs/q1";
import Q2 from "./qs/q2";
import Q3 from "./qs/q3";
import Q4 from "./qs/q4";
import Q5 from "./qs/q5";
import Q6 from "./qs/q6";
import PocetakP from "./qs/pocetakp";
import Rezultat from "./qs/rezultat";
import Footer from "../../../Footer/footer";
import Q7 from "./qs/q7";
import Q8 from "./qs/q8";

const PriceCalc = ({ lang }) => {
  const [Lang] = lang === "sr" ? SR : ENG;

  const [pocetak, setPocetak] = useState("");
  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [q3, setQ3] = useState("");
  const [q4, setQ4] = useState("");
  const [q5, setQ5] = useState("");
  const [q6, setQ6] = useState("");
  const [q7, setQ7] = useState("");
  const [q8, setQ8] = useState("");
  const [rezultat, setRezultat] = useState("");
  const [done, setDone] = useState({
    Q1done: false,
    Q2done: false,
    Q3done: false,
    Q4done: false,
    Q5done: false,
    Q6done: false,
  });

  const [showSmallCMSOffer, setShowSmallCMSffer] = useState("");
  const [showSmallStoreOffer, setShowSmallStoreOffer] = useState("");

  const topPage = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  };

  const handleShow = () => {
    topPage();
    setPocetak("-off");
    setQ1("-on");

    if (pocetak === "-off") {
      setQ1("");
      setQ2("-on");
      setDone(prevDone => ({
        ...prevDone,
        Q1done: true,
      }));
    }

    if (done.Q1done) {
      setQ2("");
      setQ3("-on");
      setDone(prevDone => ({
        ...prevDone,
        Q2done: true,
      }));
    }
    if (done.Q2done) {
      setQ3("");
      setQ4("-on");
      setDone(prevDone => ({
        ...prevDone,
        Q3done: true,
      }));
    }
    if (done.Q3done) {
      setQ4("");
      setQ5("-on");
      setDone(prevDone => ({
        ...prevDone,
        Q4done: true,
      }));
    }
    if (done.Q4done) {
      setQ5("");
      setQ6("-on");
      setDone(prevDone => ({
        ...prevDone,
        Q5done: true,
      }));
    }
    if (done.Q5done) {
      setQ6("");
      setQ7("-on");
      setDone(prevDone => ({
        ...prevDone,
        Q6done: true,
      }));
    }

    if (done.Q6done) {
      setQ7("");
      setQ8("-on");
      setDone(prevDone => ({
        ...prevDone,
        Q7done: true,
      }));
    }
    if (done.Q7done) {
      setQ8("");
      setRezultat("-on");
      setDone(prevDone => ({
        ...prevDone,
        Q8done: true,
      }));
    }
    /* Uncomment and adapt this part if additional questions are required
        if (done.Q8done) {
            setQ8('');
            setQ9('-on');
            setDone(prevDone => ({
                ...prevDone,
                Q9done: true,
            }));
        }
        if (done.Q9done) {
            setQ9('');
            setQ10('-on');
            setDone(prevDone => ({
                ...prevDone,
                Q10done: true,
            }));
        }
        if (done.Q10done) {
            setQ10('');
            setRezultat('-on');
            setDone(prevDone => ({
                ...prevDone,
                Q10done: true,
            }));
        }
        */
  };

  return (
    <React.Fragment>
      <div className="kalkulator">
        <div className="bg-dark text-secondary px-4 py-5 text-center kalkulator-inside">
          <div className="py-5">
            <h1 className="display-5 fw-bold text-white ss">{Lang.surveytitle}</h1>
            <div className="mx-auto">
              <PocetakP
                show={pocetak}
                handleShow={handleShow}
                Lang={Lang}
              />
              <Q1
                show={q1}
                handleShow={handleShow}
                Lang={Lang}
              />
              <Q2
                show={q2}
                handleShow={handleShow}
                Lang={Lang}
              />
              <Q3
                show={q3}
                handleShow={handleShow}
                Lang={Lang}
              />
              <Q4
                show={q4}
                handleShow={handleShow}
                Lang={Lang}
              />
              <Q5
                show={q5}
                handleShow={handleShow}
                Lang={Lang}
              />
              <Q6
                show={q6}
                handleShow={handleShow}
                Lang={Lang}
              />
              <Q7
                show={q7}
                handleShow={handleShow}
                Lang={Lang}
                setShowSmallCMSffer={setShowSmallCMSffer}
              />
              <Q8
                show={q8}
                handleShow={handleShow}
                Lang={Lang}
                setShowSmallStoreOffer={setShowSmallStoreOffer}
              />
              <Rezultat
                show={rezultat}
                handleShow={handleShow}
                Lang={Lang}
                showSmallCMSOffer={showSmallCMSOffer}
                showSmallStoreOffer={showSmallStoreOffer}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer lang={lang} />
    </React.Fragment>
  );
};

export default PriceCalc;
