import React from "react";
import { odg01 } from "./q1";
import { odg02 } from "./q2";
import { odg03 } from "./q3";
import { odg04 } from "./q4";
import { odg05 } from "./q5";
import { odg06 } from "./q6";
import { odg07 } from "./q7";
import { odg08 } from "./q8";
import { odg09 } from "./xq9";
import { odg10 } from "./xq10";
import InfoSmall from "../small-offer/smallInfoOffer";
import CMSSmall from "../small-offer/smallCMSOffer";
import StoreSmall from "../small-offer/smallStoreOffer";
import PrimaryButton from "../../../../../utils/button";
import ContactIcon from "../../../../a-Navbar/common/_images/kontakt";

const Rezultat = props => {
  const show = props.show;
  //const handleShow = props.handleShow;
  const Lang = props.Lang;
  const showSmallCMSOffer = props.showSmallCMSOffer;
  const showSmallStoreOffer = props.showSmallStoreOffer;

  let fin = 0;

  const handleResult = () => {
    fin = odg01 + odg02 + odg03 + odg04 + odg05 + odg06 + odg07 + odg08 + odg09 + odg10;
  };

  handleResult();
  const topPage = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  return (
    <div
      className="bg-dark text-secondary px-4 py-5 text-center"
      id={`rezultat${show}`}
    >
      <p className="rezultat-razmak mb-4">{Lang.fintitle1}</p>
      <div
        className="d-grid gap-2 d-sm-flex justify-content-sm-center rezultat-razmak"
        id="ubaciHtml"
      >
        <span
          className="text-primary display-3 text-center text-md-start "
          id="cifra"
        >
          <br />
          {Lang.lang === "SR" ? fin : fin / 2} {Lang.finvalute}
        </span>
      </div>

      <p className="rezultat-razmak mb-4">{Lang.fintitle11}</p>
      {!showSmallCMSOffer && !showSmallStoreOffer && (
        <InfoSmall
          Lang={Lang}
          TopPage={topPage}
          showSmallInfoOffer={"show"}
        />
      )}
      {!showSmallStoreOffer && (
        <CMSSmall
          Lang={Lang}
          TopPage={topPage}
          showSmallCMSOffer={showSmallCMSOffer}
        />
      )}
      <StoreSmall
        Lang={Lang}
        TopPage={topPage}
        showSmallStoreOffer={showSmallStoreOffer}
      />
      <p
        className="rezultat-razmak mb-4"
        id="underCifra"
      >
        {Lang.fintitle2} <br />
        {Lang.fintitle3}
      </p>

      <PrimaryButton
        to="/kontakt"
        name={Lang.contactbutton}
        classpass={`prime-button contactPackage smallOfferContact`}
        img={<ContactIcon />}
      />
    </div>
  );
};

export default Rezultat;
