const SocialSVG = () => {
  return (
    <div>
      <svg
        width="40px"
        height="40px"
        x="0px"
        y="0px"
        viewBox="0 0 48.908 48.908"
      >
        <g>
          <path
            fill="#0dcaf0"
            opacity="0.6"
            d="M37.294,1.492c0.294-0.315,0.293-0.88-0.243-0.973c-5.175-0.893-11.147-1.009-15.28,2.77
		c-3.843,3.513-4.025,9.333-3.166,14.149c-0.021-0.005-0.039-0.016-0.061-0.018c-2.216-0.228-4.63,0.018-6.832,0.313
		c-0.337,0.045-0.427,0.409-0.246,0.596c-0.004,0.016-0.018,0.025-0.019,0.042c-0.196,2.521-0.012,5.287,0.313,7.805
		c-0.376,0.128-0.341,0.726,0.117,0.752c2.269,0.129,4.833,0.216,7.132-0.071c-0.666,6.883-0.257,14.107-0.165,21.014
		c0.002,0.191,0.094,0.32,0.217,0.399c-0.028,0.18,0.067,0.372,0.317,0.392c2.673,0.208,5.803,0.466,8.441-0.08
		c0.137-0.028,0.21-0.135,0.251-0.255c0.133-0.025,0.259-0.113,0.276-0.29c0.649-6.772,0.686-13.789,0.272-20.587
		c2.418,0.198,5.032-0.049,7.427-0.125c0.085-0.003,0.157-0.022,0.219-0.051c0.274,0.096,0.645-0.056,0.63-0.414
		c-0.056-1.366-0.246-2.723-0.3-4.091c-0.053-1.344-0.287-3.08-0.019-4.391c0.034-0.168-0.114-0.317-0.264-0.298
		c-0.053-0.1-0.149-0.177-0.305-0.203c-2.43-0.399-5.1-0.419-7.569-0.331c-0.266-1.511-0.48-3.663-0.065-5.4
		c0.025-0.026,0.034-0.059,0.022-0.09c0.193-0.759,0.497-1.442,0.997-1.935c0.313-0.309,0.696-0.528,1.106-0.707
		c0.111-0.004,0.222-0.004,0.333-0.009c0.1-0.005,0.126-0.106,0.087-0.171c1.686-0.557,3.864-0.35,5.459-0.34
		c0.367,0.002,0.51-0.291,0.461-0.56c0.089-0.039,0.168-0.099,0.2-0.203C37.645,6.176,37.666,3.554,37.294,1.492z M28.714,9.139
		c-1.473-0.002-2.952,0.044-4.417,0.07c-0.107,0.002-0.107,0.165,0,0.167c1.357,0.025,2.725,0.064,4.089,0.069
		c-0.02,0.024-0.048,0.039-0.068,0.063c-0.606,0.749-0.94,1.678-1.088,2.674c-1.18,0.186-2.334,0.282-3.563,0.182
		c-0.089-0.007-0.085,0.126,0,0.137c1.145,0.149,2.37,0.165,3.533-0.041c-0.106,0.93-0.068,1.906,0.061,2.848
		c-1.409-0.029-2.822,0.011-4.23,0.023c-0.098,0.001-0.098,0.151,0,0.152c1.416,0.012,2.839,0.053,4.256,0.023
		c0.123,0.813,0.301,1.595,0.504,2.284c-0.01,0.017-0.008,0.038-0.016,0.056c-1.712-0.089-3.453-0.007-5.166,0.022
		c-0.088,0.002-0.088,0.136,0,0.137c1.696,0.029,3.42,0.108,5.116,0.024c-0.007,0.248,0.156,0.508,0.485,0.545
		c2.425,0.275,5.08,0.472,7.522,0.273c-0.304,0.705-0.304,1.537-0.281,2.334c-4.493-0.545-8.986-0.535-13.483-0.035
		c-0.112,0.012-0.114,0.188,0,0.177c4.518-0.418,8.986-0.338,13.492,0.173c0.003,0.108,0.007,0.219,0.009,0.325
		c0.009,0.474,0.034,0.953,0.06,1.432c-3.604,0.044-7.212,0.138-10.813,0.272c-0.089,0.003-0.09,0.139,0,0.139
		c3.606,0,7.218-0.041,10.824-0.13c0.044,0.687,0.106,1.373,0.207,2.052c-4.518-0.055-9.044-0.001-13.561,0.081
		c-0.091,0.002-0.091,0.14,0,0.141c4.533,0.083,9.075,0.137,13.609,0.081c0.023,0.14,0.04,0.281,0.067,0.42
		c-2.335-0.191-5.107-0.23-7.417,0.403c-0.263-0.243-0.782-0.142-0.789,0.323c0,0.027-0.001,0.054-0.001,0.081
		c-0.001,0.02-0.005,0.037-0.001,0.057c-0.005,0.315-0.003,0.632-0.005,0.948c-1.195-0.053-2.41,0.021-3.596,0.123
		c-0.468,0.04-1.481-0.062-1.786,0.373c-0.015,0.022-0.01,0.06,0.01,0.077c0.031,0.027,0.062,0.053,0.093,0.08
		c0.022,0.019,0.056,0.022,0.078,0c0.021-0.022,0.043-0.044,0.064-0.065c0.037-0.038-0.014-0.111-0.059-0.076
		c0.248-0.192,1.045-0.112,1.389-0.136c0.495-0.033,0.989-0.061,1.485-0.078c0.774-0.026,1.548-0.012,2.322-0.02
		c-0.002,0.554-0.007,1.106-0.008,1.66c-1.533-0.036-3.079,0.01-4.61,0.043c-0.081,0.002-0.082,0.125,0,0.127
		c1.531,0.033,3.076,0.079,4.609,0.043c-0.002,0.775-0.011,1.549-0.012,2.324c-1.4,0.209-2.757,0.369-4.18,0.3
		c-0.097-0.005-0.095,0.143,0,0.15c1.386,0.101,2.816,0.071,4.179-0.238c-0.001,0.633-0.009,1.265-0.01,1.898
		c-2.113,0.325-4.138,0.521-6.281,0.297c-0.095-0.01-0.092,0.136,0,0.148c2.065,0.271,4.237,0.29,6.28-0.191
		c0,0.677-0.012,1.351-0.01,2.028c-1.384,0.042-2.774,0.171-4.154,0.255c-0.102,0.006-0.104,0.164,0,0.16
		c1.38-0.055,2.774-0.064,4.154-0.161c0.001,0.537-0.002,1.073,0.001,1.609c-1.944-0.001-3.899,0.059-5.841,0.095
		c-0.078,0.001-0.077,0.115,0,0.12c1.917,0.134,3.909,0.177,5.841,0.047c0.003,0.513-0.001,1.026,0.003,1.539
		c-1.455,0.036-2.916,0.165-4.367,0.247c-0.081,0.005-0.082,0.13,0,0.126c1.451-0.054,2.917-0.062,4.368-0.162
		c0.006,0.771,0.001,1.543,0.012,2.314c-1.389,0.031-2.785,0.164-4.169,0.246c-0.087,0.005-0.089,0.141,0,0.137
		c1.384-0.056,2.787-0.063,4.172-0.17c0.01,0.73,0.016,1.461,0.031,2.19c-1.683-0.001-3.36,0.128-5.047,0.1
		c-0.085-0.001-0.083,0.124,0,0.131c1.646,0.133,3.412,0.205,5.05-0.05c0.014,0.67,0.02,1.341,0.038,2.01
		c-2.477-0.408-5.333-0.129-7.851,0.144c0.001-0.016,0.009-0.027,0.009-0.044c0.075-7.085,0.59-14.444,0.016-21.499
		c-0.039-0.476-0.618-0.517-0.781-0.146c-2.167-0.279-4.57-0.218-6.75-0.101c0.342-2.459,0.287-5.269-0.339-7.668
		c2.176,0.148,4.519,0.21,6.662-0.146c0.213-0.035,0.309-0.173,0.323-0.329c0.196,0.001,0.403-0.129,0.401-0.362
		c-0.038-4.59-0.866-9.64,2.836-13.179c3.838-3.669,9.38-3.477,14.26-2.775c-0.07,0.3-0.117,0.626-0.169,0.946
		c-1.667-0.236-3.383-0.278-5.059-0.401c-0.119-0.009-0.116,0.172,0,0.184c1.66,0.166,3.344,0.42,5.013,0.474
		c-0.086,0.585-0.151,1.184-0.176,1.791c-3.269-0.446-6.615-0.455-9.899-0.259c-0.083,0.005-0.085,0.133,0,0.131
		c3.325-0.095,6.586,0.129,9.891,0.4c-0.025,1.032,0.043,2.057,0.237,2.966C34.074,7.665,30.575,7.44,28.714,9.139z"
          />
          <path
            fill="#0dcaf0"
            opacity="0.6"
            d="M35.885,7.321c-3.358-0.385-6.734-0.674-10.102-0.95c-0.1-0.008-0.096,0.142,0,0.154
		c3.36,0.401,6.731,0.792,10.102,1.085C36.072,7.627,36.066,7.342,35.885,7.321z"
          />
        </g>
      </svg>

      <svg height="40px" x="0px" y="0px" viewBox="0 0 511.999 511.999">
        <g>
          <g>
            <path
              fill="#0dcaf0"
              opacity="0.5"
              d="M507.77,96.789c-3.92-4.669-9.903-6.958-15.769-6.358c2.345-4.855,4.354-9.891,6.016-15.091
			c2.279-7.127-0.123-15.046-5.97-19.699c-5.849-4.662-14.103-5.232-20.545-1.416c-14.758,8.753-30.599,15.287-47.157,19.455
			c-21.035-18.575-48.714-29.128-76.82-29.128c-63.816,0-115.735,51.917-115.735,115.731c0,0.714,0.006,1.428,0.019,2.142
			c-64.801-8.793-124.951-42.372-166.494-93.332c-3.765-4.618-9.554-7.099-15.467-6.617C43.922,62.94,38.597,66.283,35.6,71.42
			c-10.258,17.603-15.68,37.727-15.68,58.199c0,18.054,4.218,35.74,12.145,51.67c-1.32,0.393-2.602,0.936-3.818,1.634
			c-5.477,3.138-8.967,9.007-9.111,15.299c-0.013,0.551-0.014,1.102-0.014,1.659c0,32.878,14.268,64.167,38.287,85.933
			c-3.841,4.722-5.072,11.157-3.206,16.976c9.946,31.053,32.666,56.265,61.702,69.726C92.746,383.153,67.381,388.7,41.425,388.7
			c-7.086,0-14.204-0.416-21.147-1.238c-8.439-1.004-16.336,3.885-19.202,11.89c-2.863,8,0.132,16.789,7.282,21.373
			c47.672,30.566,102.793,46.722,159.404,46.722c111.254,0,180.924-52.547,219.777-96.631
			c48.338-54.836,76.06-127.446,76.06-199.211c0-1.265-0.008-2.537-0.024-3.811c17.576-13.928,32.828-30.48,45.386-49.265
			C513.42,111.86,512.927,102.917,507.77,96.789z M447.131,154.728c-2.645,1.993-4.158,5.147-4.057,8.456
			c0.086,2.799,0.129,5.633,0.129,8.425c0,66.867-25.866,134.561-70.965,185.723c-36.075,40.928-100.834,89.716-204.475,89.716
			c-49.475,0-97.73-13.257-140.171-38.423c4.596,0.316,9.222,0.475,13.837,0.475c37.456,0,73.838-10.387,105.213-30.038
			c3.502-2.195,5.335-6.287,4.64-10.361c-0.697-4.075-3.783-7.326-7.816-8.234c-31.581-7.104-57.553-30.05-68.608-60.322
			c1.701,0.258,3.412,0.473,5.115,0.644c4.75,0.466,9.202-2.416,10.699-6.954c1.497-4.539-0.358-9.508-4.462-11.956
			c-28.06-16.734-45.763-46.996-46.651-79.439c3.985,2.013,8.121,3.766,12.341,5.228c4.347,1.505,9.163-0.084,11.758-3.882
			c2.597-3.797,2.33-8.861-0.651-12.365c-14.629-17.197-22.685-39.144-22.685-61.799c0-15.768,3.9-31.279,11.306-45.069
			c47.495,56.842,116.675,93.062,190.337,99.529c3.076,0.267,6.105-0.866,8.244-3.091s3.155-5.3,2.764-8.36
			c-0.519-4.065-0.782-8.219-0.782-12.344c0-52.566,42.768-95.332,95.336-95.332c24.725,0,49.052,9.914,66.741,27.198
			c2.46,2.403,5.965,3.408,9.323,2.665c18.548-4.087,36.34-10.801,52.975-19.987c-4.049,10.366-9.853,19.856-17.303,28.281
			c-3.006,3.399-3.405,8.376-0.978,12.211c2.426,3.835,7.097,5.607,11.453,4.344c6.018-1.74,11.985-3.781,17.819-6.092
			C476.151,129.269,462.58,143.088,447.131,154.728z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#0dcaf0"
              opacity="0.5"
              d="M89.95,145.834c-2.153-1.605-4.298-3.258-6.377-4.91c-4.409-3.506-10.826-2.774-14.331,1.636
			c-3.505,4.409-2.773,10.825,1.636,14.331c2.241,1.782,4.554,3.564,6.874,5.294c1.832,1.366,3.97,2.025,6.091,2.025
			c3.11,0,6.182-1.418,8.183-4.102C95.394,155.592,94.465,149.203,89.95,145.834z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#0dcaf0"
              opacity="0.5"
              d="M222.328,196.399c-36.715-3.223-73.142-14.6-105.344-32.9c-4.898-2.783-11.123-1.07-13.907,3.828
			c-2.783,4.898-1.07,11.123,3.828,13.907c34.73,19.738,74.026,32.009,113.639,35.487c0.304,0.026,0.605,0.04,0.904,0.04
			c5.229,0,9.682-4,10.148-9.309C232.088,201.84,227.939,196.892,222.328,196.399z"
            />
          </g>
        </g>
      </svg>

      <span> </span>

      <svg width="40px" height="40px" x="0px" y="0px" viewBox="0 0 512 512">
        <g>
          <g>
            <g>
              <path
                fill="#0dcaf0"
                opacity="0.5"
                d="M359.312,0H152.689C68.849,0,0.64,68.208,0.64,152.049v207.903C0.64,443.792,68.849,512,152.689,512h206.623
				c83.839,0,152.048-68.208,152.048-152.048V152.049C511.359,68.208,443.151,0,359.312,0z M490.961,359.951
				c0,72.593-59.057,131.65-131.649,131.65H152.689c-72.593,0-131.65-59.057-131.65-131.649V152.049
				c0-72.593,59.057-131.65,131.65-131.65h206.623c72.592,0,131.649,59.057,131.649,131.65V359.951z"
              />
              <path
                fill="#0dcaf0"
                opacity="0.5"
                d="M359.312,58.294H152.689c-51.698,0-93.756,42.057-93.756,93.755v207.904c0,51.697,42.058,93.755,93.756,93.755h206.623
				c51.697,0,93.755-42.058,93.755-93.755V152.049C453.067,100.352,411.009,58.294,359.312,58.294z M432.669,359.951
				c0,40.449-32.908,73.357-73.357,73.357H152.689c-40.45,0-73.358-32.908-73.358-73.357V152.048
				c0-40.449,32.908-73.357,73.358-73.357v0.001h206.623c40.449,0,73.357,32.908,73.357,73.357V359.951z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                fill="#0dcaf0"
                opacity="0.5"
                d="M256,124.178c-72.686,0-131.821,59.135-131.821,131.822S183.313,387.821,256,387.821S387.821,328.687,387.821,256
				S328.686,124.178,256,124.178z M256,367.422c-61.438,0-111.422-49.984-111.422-111.422c0-61.439,49.984-111.423,111.422-111.423
				c61.439,0,111.422,49.984,111.422,111.423C367.422,317.438,317.438,367.422,256,367.422z"
              />
              <path
                fill="#0dcaf0"
                opacity="0.5"
                d="M256,181.041c-41.332,0-74.959,33.627-74.959,74.959s33.627,74.959,74.959,74.959c41.333,0,74.959-33.627,74.959-74.959
				S297.332,181.041,256,181.041z M256,310.561c-30.085,0-54.561-24.476-54.561-54.561s24.476-54.561,54.561-54.561
				s54.561,24.475,54.561,54.561C310.561,286.085,286.085,310.561,256,310.561z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#0dcaf0"
              opacity="0.4"
              d="M380.485,99.843c-17.464,0-31.673,14.208-31.673,31.673s14.208,31.672,31.673,31.672
			c17.463,0,31.672-14.207,31.672-31.672S397.948,99.843,380.485,99.843z M380.485,142.789c-6.216,0-11.274-5.057-11.274-11.273
			c0-6.217,5.058-11.274,11.274-11.274s11.273,5.058,11.273,11.274C391.759,137.732,386.702,142.789,380.485,142.789z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#0dcaf0"
              opacity="0.3"
              d="M239.681,463.044h-95.872c-5.633,0-10.199,4.566-10.199,10.199s4.566,10.199,10.199,10.199h95.872
			c5.633,0,10.199-4.566,10.199-10.199S245.314,463.044,239.681,463.044z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#0dcaf0"
              opacity="0.2"
              d="M278.438,463.044h-6.12c-5.632,0-10.199,4.566-10.199,10.199s4.567,10.199,10.199,10.199h6.12
			c5.633,0,10.199-4.566,10.199-10.199S284.07,463.044,278.438,463.044z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default SocialSVG;
