import React from "react";
import CloseSVG from "../../../_styles/icons/close";

export default function PresenationVideo({ top, presentation, setPresentation }) {
  const closePreview = e => {
    setPresentation("");
    document.body.style.overflow = "auto";
  };

  return (
    <div
      style={{ top: top }}
      className={`package-example-preview ${presentation ? "show" : ""}`}
      onClick={e => {
        closePreview(e);
      }}
    >
      <video
        controls
        autoPlay
      >
        <source
          src={presentation}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <button
        className="button-close-preview"
        onClick={() => {
          closePreview();
        }}
      >
        <CloseSVG />
      </button>
    </div>
  );
}
