import React from "react";
import ENG from "./lang/eng";
import SR from "./lang/sr";
import construction from "./_images/construction.png";
import Footer from "../Footer/footer";



const Shop = ({ lang }) => {
  const [Lang] = lang === "sr" ? SR : ENG;
  return (
    <div id="shop">
      <h1>{Lang.underconstr}</h1>
      <div className="constructionImgDiv">
        <img
          className="constructionImg"
          alt="Construction Site"
          src={construction}
        />
      </div>
      <Footer lang={lang} />
    </div>
  );
};

export default Shop;
